import { useAppDispatch } from '../store/store';
import useAuthenticatedRequest from '../hooks/useAuthenticatedRequest';
import * as actions from '../store/client';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppSelector } from '../store/store';
import {
  Client,
  CreateClientBody,
  NewClientWithTherapist,
  SettingsData,
} from '../types';
import { format } from 'date-fns';
import useWatchSettingsService from './useWatchSettingsService';
import { showToast } from '../store/alerts';

const useClientService = () => {
  const { fetchRequest } = useAuthenticatedRequest();
  const dispatch = useAppDispatch();
  const baseUrl = process.env.REACT_APP_API_URL;
  const { user } = useAuth0();
  const { userProfile } = useAppSelector((state) => state.profile);
  const { clients } = useAppSelector((state) => state.client);
  const { createWatchSettings } = useWatchSettingsService();

  const createClient = async (
    data: NewClientWithTherapist,
    device: any,
    settings: SettingsData,
  ) => {
    if (!user) return;
    try {
      dispatch(actions.createClient());
      const watch = {
        deviceId: device.id,
        deviceName: device.name,
      };
      const body = {
        ...data,
        watch,
      };
      const client = await fetchRequest(`${baseUrl}/client/create-client`, {
        method: 'POST',
        body,
      });
      await createWatchSettings(settings, client.id);
      dispatch(actions.createClientSuccess(client));
      getAllClientsByTherapistId();
      return client;
    } catch (error: any) {
      console.error(error);
      dispatch(actions.createClientFailure(error));
      dispatch(
        showToast({
          summary: 'Error creating client',
          severity: 'error',
          detail: error.message || 'Unable to create client, please try again',
        }),
      );
    }
  };

  const getClientById = async (id: string) => {
    try {
      dispatch(actions.getClientById());
      let client = clients.find((client) => client.id === id);
      if (client !== undefined) {
        dispatch(actions.getClientByIdSuccess(client));
      } else {
        client = await fetchRequest(`${baseUrl}/client/get-client-by-id`, {
          method: 'GET',
          params: {
            id,
          },
        });
        dispatch(actions.getClientByIdSuccess(client));
      }
    } catch (error) {
      dispatch(actions.getClientByIdFailure(error));
    }
  };

  const getAllClientsByTherapistId = async () => {
    if (!user) return;
    try {
      dispatch(actions.getAllClientsByTherapistId());
      const clients = await fetchRequest(
        `${baseUrl}/client/get-all-clients-by-therapist-id`,
        {
          method: 'GET',
          params: {
            id: userProfile?.id,
          },
        },
      );
      const formattedClients: Client[] = clients.map((client: Client) => ({
        ...client,
        dateOfBirth: format(new Date(client.dateOfBirth), 'MMM dd, yyyy'),
      }));
      dispatch(actions.getAllClientsByTherapistIdSuccess(formattedClients));
    } catch (error) {
      dispatch(actions.getAllClientsByTherapistIdFailure(error));
    }
  };

  return {
    getClientById,
    createClient,
    getAllClientsByTherapistId,
  };
};

export default useClientService;
