import { useAuth0 } from '@auth0/auth0-react';
import { differenceInYears, parseISO } from 'date-fns';
import useAuthenticatedRequest from '../hooks/useAuthenticatedRequest';
import { showToast } from '../store/alerts';
import { useAppDispatch, useAppSelector } from '../store/store';
import * as actions from '../store/watch';
import { AddWatchBody, LogEntry, SaveWatchDataBody, Watch, WatchData } from '../types';
import { dateAsUTC } from '../helpers/timehelpers';

const useWatchService = () => {
  const { fetchRequest } = useAuthenticatedRequest();
  const dispatch = useAppDispatch();
  const { client } = useAppSelector((state) => state.client);
  const { watchSettings } = useAppSelector((state) => state.watch);
  const { heartRateDefaults } = useAppSelector(
    (state) => state.storedConstants,
  );
  const baseUrl = process.env.REACT_APP_API_URL;
  const { user } = useAuth0();

  const addWatch = async (device: any) => {
    try {
      if (!user || !client) throw new Error('User or client not found');
      let clientAge = differenceInYears(
        new Date(),
        parseISO(client?.dateOfBirth as any),
      );
      const defaultHr = heartRateDefaults.find((d) => {
        if (clientAge >= 18) {
          // we only have data for 18 and younger
          clientAge = 18;
        }
        if (clientAge === 0) {
          // no data for age of 0, defaulting to 1.
          clientAge = 1;
        }
        return d.age === clientAge;
      });
      if (!defaultHr) throw new Error('No default heart rate found');
      dispatch(actions.addWatch());
      const watch = await fetchRequest(`${baseUrl}/watch/add-watch`, {
        method: 'POST',
        body: {
          clientId: client.id,
          restingHr: defaultHr?.bpmAverage,
          triggerHr: defaultHr?.bpmAverage * 2, // trigger is 2x the average
          // the following values may need to be defined by the client
          triggerExerciseSeconds: 30,
          noTimeoutSeconds: 30,
          ignoreTimeoutSeconds: 30,
          successTimeoutSeconds: 30,
          breathingCycles: 2,
          deviceId: device.id,
          deviceName: device.name,
        } as AddWatchBody,
      });
      dispatch(actions.addWatchSuccess(watch));
    } catch (error) {
      console.error('Error adding watch', error);
      dispatch(actions.addWatchFailure(error));
      dispatch(
        showToast({
          severity: 'error',
          summary: 'Error adding watch',
          detail: 'Unable to add watch',
        }),
      );
    }
  };

  const addEventLogData = async (
    data: LogEntry[],
  ) => {
    try {
      if (!user || !client || !watchSettings)
        throw new Error('User or client not found');
      dispatch(actions.saveWatchData());
      let eventLogResponse = await fetchRequest(
        `${baseUrl}/watch-event-log/${client.watch.id}`,
        {
          method: 'POST',
          body: data,
        },
      );
      // }
      dispatch(actions.saveWatchDataSuccess(eventLogResponse));
    } catch (error) {
      console.error('Error adding event log data', error);
      dispatch(actions.saveWatchDataFailure(error));
      dispatch(
        showToast({
          severity: 'error',
          summary: 'Error adding eventlog data',
          detail: 'Unable to save watch data.',
        }),
      );
      throw error;
    }
  };

  const getWatchData = async (
    resultsPerPage: number,
    page: number,
    searchQuery: string,
    orderBy?: string,
    orderDirection?: string,
    startDate?: Date,
    endDate?: Date,
    filter?: string,
  ): Promise<any> => {
    try {
      dispatch(actions.getWatchData());
      let params: any = {
        take: resultsPerPage,
        skip: page,
        whereBy:
          'eventDate,postActionHr,finalHr,downloadDate,triggerHr,viewed,actionTaken,actionRepeated,eventNotes',
        where: searchQuery,
        orderBy: orderBy || 'eventDate',
        orderDir: orderDirection || 'desc',
        filter: filter,
      };

      if (startDate && endDate) {
        params = {
          ...params,
          startDate: dateAsUTC(startDate).toISOString(),
          endDate: dateAsUTC(endDate).toISOString(),
        };
      }
      const response = await fetchRequest(
        `${baseUrl}/watch/get-watch-data/${client?.watch.id}`,
        {
          method: 'GET',
          params,
        },
      );
      dispatch(actions.getWatchDataSuccess(response));
    } catch (error: any) {
      console.error('Error getting watch data', error);
      dispatch(actions.getWatchDataFailure(error));
      dispatch(
        showToast({
          severity: 'error',
          summary: 'Error getting watch data',
          detail: 'Unable to get watch data.',
        }),
      );
    }
  };
  //TODO: Test this when we have watch data in the BE
  const updateWatchEventNotes = async (id: number, eventNotes: string) => {
    try {
      dispatch(actions.updateWatchEventNotes());
      const response = await fetchRequest(
        `${baseUrl}/watch/update-watch-event-notes/${id}`,
        {
          method: 'PUT',
          body: {
            eventNotes,
          },
        },
      );
      dispatch(actions.updateWatchEventNotesSuccess(response));
      dispatch(
        showToast({
          severity: 'success',
          summary: 'Event notes saved!',
        }),
      );
    } catch (error: any) {
      console.error('Error saving note', error);
      dispatch(actions.updateWatchEventNotesFailure(error));
      dispatch(
        showToast({
          severity: 'error',
          summary: 'Error saving note',
          detail: 'Unable to update watch data.',
        }),
      );
    }
  };

  const getChartData = async (start: Date, end: Date) => {
    try {
      dispatch(actions.getChartData());
      const response = await getWatchDataRange(start, end);
      dispatch(actions.getChartDataSuccess(response));
    } catch (error) {
      console.error('Error getting chart data', error);
      dispatch(actions.getChartDataFailure(error));
      dispatch(
        showToast({
          severity: 'error',
          summary: 'Error getting chart data',
          detail: 'Unable to get chart data.',
        }),
      );
    }
  };

  const getWatchDataRange = async (start: Date, end: Date) => {
    const response: Promise<{ data: WatchData[]; count: number }> =
      await fetchRequest(
        `${baseUrl}/watch/get-watch-data/${client?.watch.id}`,
        {
          method: 'GET',
          params: {
            start: dateAsUTC(start).toISOString(),
            end: dateAsUTC(end).toISOString(),
          },
        },
      );
    return response;
  };

  const postEpisodeBuffers = async (bufs64:string[]) => {
    console.log("postEpisodeBuffers");
    try {
      if (!user || !client || !watchSettings)
        throw new Error('User or client not found');
      dispatch(actions.saveWatchData());
      console.log("About to fetch")
      let resp = await fetchRequest(
        `${baseUrl}/watch-episode-buffers/${client.watch.id}`,
        {
          method: 'POST',
          body: bufs64,
        },
      );
      dispatch(actions.saveWatchDataSuccess(resp));
    } catch (error) {
      console.error('Error adding episode buffers', error);
      dispatch(actions.saveWatchDataFailure(error));
      dispatch(
        showToast({
          severity: 'error',
          summary: 'Error adding episode data',
          detail: 'Failed call to server.',
        }),
      );
      throw error;
    }
  };

  return {
    addWatch,
    getWatchData,
    addEventLogData,
    updateWatchEventNotes,
    getChartData,
    getWatchDataRange,
    postEpisodeBuffers,
  };
};

export default useWatchService;
