// Extra time helpers.
// Because the watch has no time zone concept, and timestamps are
// stored in the DB without any time zone, yet they are serialized
// on the API as UTC.
// Meanwhile, the frontend charts and date-fns favor everything
// in browser-local time.
// To prevent annoying time zone and DST conversions, we have these
// utility functions.  As a design convention, we'll keep all dates
// on the frontend as browser-local times, and only convert them to
// "zoneless" times (represented as UTC) just before serialzing them on
// the REST call.

import { startOfDay, endOfDay, addDays } from 'date-fns';


/**
 * Reinterprets the given date d as being in local time.
 * Note, does not *convert* from UTC, but rather just 'resets' the time zone.
 */
export function dateAsLocal(date:Date|string) : Date {
    const d:Date = new Date(date);
    return new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(),
        d.getUTCHours(), d.getUTCMinutes(), d.getUTCSeconds());
}

/**
 * Reinterprets the given date d as being in UTC time.
 * Note, does not *convert* from local, but rather just 'resets' the time zone.
 */
export function dateAsUTC(date:Date|string) : Date {
    const d:Date = new Date(date); // copy/parse
    return new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(),
        d.getHours(), d.getMinutes(), d.getSeconds()));
}

/**
 * Take a timestamp from the watch (seconds since Y2K), and create a JS Date
 * in browser-local time matching the same intended time.
 * @param stamp Seconds since 2000-01-01T00:00:00
 * @return a Date object with the watch date/time and the timezone set to browser.
 */
export function fromTimestamp2k(stamp:number) : Date {
    const Epoch2000 = 946684800;
    const unixMs = (Epoch2000 + stamp)*1000;
    const dt : Date = new Date(unixMs); // watch local time as UTC
    return dateAsLocal(dt); // reinterpret
}

/**
 * Take a browser-local date and turn it into a timestamp (seconds since Y2K).
 * @param localDate Date object in browser time
 * @returns Seconds since 2000-01-01T00:00:00 in unspecified time zone.
 */
export function toTimestamp2k(localDate:Date) : number {
    const utc:Date = dateAsUTC(localDate);
    const Epoch2000 = 946684800;
    return (utc.getTime() / 1000) - Epoch2000;
}

/**
 * Strong type for a date range.  Can be any range, but by convention
 * will be the start and end of the day respectively, in browser-local time.
 */
export type DateRange = { startDate:Date, endDate:Date };

/**
 * Return a range for the start and end of the given day, in local time.
 */
export function dayRange(date:Date): DateRange {
    const start = startOfDay(date);
    const end = endOfDay(date);
    return { startDate: start, endDate: end };
}

/**
 * Return a range for the 7-day period ending on the given date.
 * The range does not have to align with a calendar week, and always
 * includes the full endDate.
 */
export function weekRange(endDate:Date): DateRange {
    const start = startOfDay(addDays(endDate, -6)); // keep endDate inclusive
    const end = endOfDay(endDate);
    return { startDate: start, endDate: end };
}

/**
 * Return a range for the 28-day period ending on the given date.
 * The range does not have to align with calendar weeks or months,
 * and always includes the full endDate.
 */
export function fourWeekRange(endDate:Date): DateRange {
    const start = startOfDay(addDays(endDate, -27)); // keep endDate inclusive
    const end = endOfDay(endDate);
    return { startDate: start, endDate: end };
}