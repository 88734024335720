// types that are shared between BE and FE

export interface AddWatchBody {
  clientId: string;
  restingHr: number;
  triggerHr: number;
  triggerExerciseSeconds: number;
  noTimeoutSeconds: number;
  ignoreTimeoutSeconds: number;
  successTimeoutSeconds: number;
  breathingCycles: number;
  deviceId: string;
  deviceName: string;
}

export interface SaveWatchDataBody {
  watchId: number;
  settingsId: number;
  data: {
    rawData: {
      realBPM: number;
      calculatedBPM: number;
      timestamp: number;
      event: string;
    }[];
    watchData: any[];
  };
}

export interface CreateClientBody {
  firstName: string;
  lastName: string;
  serviceType: number;
  dateOfBirth: Date;
  externalClientId?: string;
  therapist: string;
  watch: {
    deviceId: string;
    deviceName: string;
  };
}

export class Time {
  constructor(public hour:number, public minute:number) {}

  public toString() : string {
    const hr = String(this.hour).padStart(2, '0');
    const mn = String(this.minute).padStart(2, '0');
    return `${hr}:${mn}`;
  }

  public static parse(str:string|null) : Time|null {
    if (!str) {
      return null;
    }
    const res = str.match(/(\d+):(\d+)/);
    if (res != null) {
      return new Time(parseInt(res[1]), parseInt(res[2]));
    }
    return new Time(12,0); //TODO throw error
  }

  public toDate() : Date {
    return new Date(1970, 1, 1, this.hour, this.minute, 0);
  }

  // Date should be UTC but treated as a local unspecified TZ
  public static fromDate(date:Date|null) : Time|null {
    return date 
      ? new Time(date.getUTCHours(), date.getUTCMinutes())
      : null;
  }
};

// Emoji prompts are TIME in the DB, Date in prisma, 
// string on the API, and Time on the frontend.
export interface WatchSettingsDto {
  restingHr: number;
  timeoutNavigation: number;
  timeoutUserPrompt: number;
  timeoutBluetooth: number;
  triggerHr: number;
  numberOfCycles: number;
  emojiPrompt1: string | null; // these serialized as string
  emojiPrompt2: string | null;
  emojiPrompt3: string | null;
}

export interface CreateWatchSettingsBody extends WatchSettingsDto {
  clientId: string;
}

export interface LogEntry {
  timestamp : Date;
  code : number;
  param : number;
}

export interface CreateLogEntriesBody {
  watchId : number;
  entries : LogEntry[];
}

enum EmojiResponse {
  Timeout = 0,
  HappyGreen = 1,
  SadBlue = 2,
  AngryRed = 3,
  AgitatedYellow = 4
}
export interface EmojiCheckinDto {
  timestamp : Date;
  emojiResponse: EmojiResponse;
}
