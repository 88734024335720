import React, { useEffect, useState } from 'react';
import { motion, useReducedMotion } from 'framer-motion';
import { useAppSelector } from '../../../shared/store/store';
import EpisodeChart from '../../components/EpisodeChart';
import TabSelect from '../../components/TabSelect';
import ChartDatePicker from './../../components/ChartDatePicker';
import useWatchService from '../../../shared/services/useWatchService';
import useEmojiService from '../../../shared/services/useEmojiService';
import { parseISO, addDays, startOfDay, endOfDay, formatDistanceToNow, addWeeks, addMonths } from 'date-fns';
import { dayRange, weekRange, fourWeekRange, DateRange } from '../../../shared/helpers/timehelpers';
import TotalTriggeredEvents from '../../components/TotalTriggeredEvents';
import useChartData from '../../../shared/hooks/useChartData';
import BPMChange from '../../components/BPMChange';
import DashboardHeader from '../../components/DashboardHeader';
import EmojiTable from '../../components/EmojiTable';
import { EmojiCheckinDto, WatchData } from '../../../shared/types';
import CompliancePanel from '../../components/CompliancePanel';

const Charts: React.FC = () => {
  const { client } = useAppSelector((state) => state.client);
  const [activeView, setActiveView] = useState<'week' | 'day' | 'month'>(
    'week',
  );
  const [date, setDate] = useState<Date>(new Date());
  const { chartData, importCounter } = useAppSelector((state) => state.watch);
  const [ previousData, setPreviousData ] = useState<WatchData[]>([]);
  const [ emojiData, setEmojiData ] = useState<EmojiCheckinDto[]>([]);
  const { getChartData, getWatchDataRange } = useWatchService();
  const { getEmojiData } = useEmojiService();
  const [lastUpdated, setLastUpdated] = useState<Date | null>(null);
  const [dataAvailable, setDataAvailable] = useState(false);

  const reducedMotion = useReducedMotion();
  // triggers fetch for data for previous week charts
  // FIXME: causes many fetches??
  useChartData();

  // Select the previous interval.
  // Note this is from the calendar widget so it is more logical to
  // jump a calendar month instead of four weeks.
  const handleSelectPrevious = () => {
    if (activeView === 'day') {
      setDate(addDays(date, -1));
    } else if (activeView === 'week') {
      setDate(addWeeks(date, -1));
    } else if (activeView === 'month') {
      setDate(addMonths(date, -1));
    }
    console.log(date);
  };

  // Select the next interval.
  // Note this is from the calendar widget so it is more logical to
  // jump a calendar month instead of four weeks.
  const handleSelectNext = () => {
    if (activeView === 'day') {
      setDate(addDays(date, 1));
    } else if (activeView === 'week') {
      setDate(addWeeks(date, 1));
    } else if (activeView === 'month') {
      setDate(addMonths(date, 1));
    }
    console.log(date);
  };

  const numDays = () => {
    switch (activeView) {
        case 'day': return 1;
        case 'week': return 7;
        case 'month': return 28;
        default: return 7;
    }
  }

  useEffect(() => {
    if (!client) return;
    var thisRange: DateRange;
    var prevRange: DateRange; // start/end of previous interval, for comparisions
    if (activeView === 'day') {
      thisRange = dayRange(date);
      const dayAgo = addDays(date, -1);
      prevRange = dayRange(dayAgo);
    } else if (activeView === 'week') {
      thisRange = weekRange(date);
      const weekAgo = addWeeks(date, -1);
      prevRange = weekRange(weekAgo);
    } else { //if (activeView === 'month') {
      thisRange = fourWeekRange(date);
      const fourWeeksAgo = addWeeks(date, -4);
      prevRange = fourWeekRange(fourWeeksAgo);
    }

    // inner async invocation
    const getEmojiAsync = async () => {
      console.log("Fetching emoji data...");
      setEmojiData(await getEmojiData(thisRange.startDate, thisRange.endDate));
    };

    const getPreviousAsync = async () => {
      console.log("Fetching previous interval data...");
      const { data } = await getWatchDataRange(prevRange.startDate, prevRange.endDate);
      setPreviousData(data);
    };

    getChartData(thisRange.startDate, thisRange.endDate);
    getPreviousAsync();
    getEmojiAsync();
  }, [date, client, activeView, importCounter]);

  useEffect(() => {
    if (chartData) {
    }
  }, [JSON.stringify(chartData)]);

  //TODO: Get last updated info from db
  useEffect(() => {
    if (client && client.watch) {
      setLastUpdated(parseISO(client.watch.updatedOn as unknown as string));
    }
  }, [JSON.stringify(client)]);

  return (
    <div className="w-full px-8 py-6">
      {client && (
        <>
          <DashboardHeader client={client} />

          <main className="mt-6 grid grid-cols-3 grid-rows-[1fr_1fr_min-content] gap-x-4 gap-y-4">
            <motion.div className="col-span-3 row-span-2 flex flex-col justify-center rounded-md border border-gray-200 bg-white px-8">
              <div className="flex items-center justify-between">
                <div className="my-6 flex flex-col">
                  <h2 className=" text-xl font-medium ">Heart Rate Levels</h2>
                  <div className="mt-2 flex items-center gap-x-2">
                    <span className=" relative flex h-3 w-3">
                      <span className="absolute inline-flex h-full w-full rounded-full bg-cyan-600 opacity-75 motion-safe:animate-ping"></span>
                      <span className="inline-flex h-3 w-3 rounded-full bg-cyan-700"></span>
                    </span>
                    {lastUpdated && (
                      <p className="text-gray-500">
                        Last updated:{' '}
                        {formatDistanceToNow(lastUpdated, { addSuffix: true })}
                      </p>
                    )}
                  </div>
                </div>
                <div className="flex">
                  <TabSelect
                    dayViewActive={activeView === 'day'}
                    handleSelectDayView={() => setActiveView('day')}
                    weekViewActive={activeView === 'week'}
                    handleSelectWeekView={() => setActiveView('week')}
                    monthViewActive={activeView === 'month'}
                    handleSelectMonthView={() => setActiveView('month')}
                  />
                  <ChartDatePicker
                    handleSelectPrevious={handleSelectPrevious}
                    handleSelectNext={handleSelectNext}
                    date={date}
                    setDate={setDate}
                  />
                </div>
              </div>
              <EpisodeChart
                date={date}
                dayViewActive={activeView === 'day'}
                weekViewActive={activeView === 'week'}
                monthViewActive={activeView === 'month'}
              />
              <h2 className=" text-xl font-medium mb-2">Emoji Check-ins</h2>
              <EmojiTable
                  data={emojiData}
                  dateEnd={date}
                  numDays={numDays()}
              />
            </motion.div>

            <motion.div
              className="flex max-h-max flex-col rounded-md border border-gray-200 p-6 shadow-sm transition-shadow hover:shadow-lg"
              whileHover={{ scale: 1.04 }}
            >
              <TotalTriggeredEvents
                currentCount={chartData.length}
                previousCount={previousData.length}
                description="breathing exercises"
                intervalLabel={activeView}
              />
              {/*<MostEvents />*/}
            </motion.div>
            <motion.div
              className="flex max-h-max flex-col rounded-md border border-gray-200 p-6 shadow-sm transition-shadow hover:shadow-lg"
              whileHover={{ scale: 1.04 }}
            >
              <CompliancePanel
                emojiData={emojiData}
              />
            </motion.div>
            <motion.div
              className="flex max-h-max flex-col rounded-md border border-gray-200 p-6 shadow-sm transition-shadow hover:shadow-lg"
              whileHover={{ scale: 1.04 }}
            >
              <BPMChange />
            </motion.div>
          </main>
        </>
      )}
    </div>
  );
};

export default Charts;
