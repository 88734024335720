import useChartData from '../../../shared/hooks/useChartData';
import { WatchData } from '../../../shared/types';

/*
 * Generic widget that will show the current count, a description,
 * and a comparison with a previous value.
 */
const TotalTriggeredEvents = ({
  currentCount,
  previousCount,
  description,
  intervalLabel
} : {
  currentCount: number,
  previousCount: number,
  description: string,
  intervalLabel: ('week' | 'day' | 'month')
}) => {
  const diffCount = currentCount - previousCount;

  const renderDifference = () => {
    if (diffCount > 0) {
      return (
        <span>
          {diffCount} more than previous {intervalLabel}
        </span>
      );
    } else if (diffCount < 0) {
      return (
        <span>
          {-diffCount} less than previous {intervalLabel}
        </span>
      );
    } else {
      return (
        <span>
          Same as previous {intervalLabel}
        </span>
      );
    }
  };

  return (
    <div>
      <div className="text-xl mb-3 font-bold">
        Summary
      </div>
      <span className="text-3xl font-bold">{currentCount}</span>
      <p className="text-gray-400">Total {description} this {intervalLabel}</p>
      <div
        className={`mb-6 flex items-center gap-x-2 font-bold ${
          diffCount < 0
            ? 'text-green-600'
            : diffCount === 0
            ? ''
            : 'text-red-500'
        }`}
      >
        <i
          className={`pi ${
            diffCount < 0
              ? 'pi-arrow-down'
              : diffCount === 0
              ? ''
              : 'pi-arrow-up'
          }`}
        />
        <p>
          {renderDifference()} ({previousCount})
        </p>
      </div>
    </div>
  );
};

export default TotalTriggeredEvents;
