import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import useBluetooth from '../../../shared/bluetooth-module/useBluetooth';
import useClientService from '../../../shared/services/useClientService';
import useWatchService from '../../../shared/services/useWatchService';
import useWatchSettingsService from '../../../shared/services/useWatchSettingsService';
import { showToast } from '../../../shared/store/alerts';
import { useAppDispatch, useAppSelector } from '../../../shared/store/store';
import { CallState, LoadingState, LogEntry, NewClient, SettingsData, Time } from '../../../shared/types';
import NewClientFormHeader from '../NewClientFormHeader';
import NewClientStep1 from '../NewClientStep1';
import NewClientStep2 from '../NewClientStep2';
import NewClientStep3 from '../NewClientStep3';
import { CancellationToken } from '../../../shared/helpers/CancellationToken';

const NewClientForm: React.FC<{
  handleCloseModal: () => void;
}> = ({ handleCloseModal }) => {
  const steps = [
    { label: ' Client info', header: 'Add a new client' },
    { label: 'Connect watch', header: "Pair new client's watch" },
    { label: 'Sync', header: 'Synchronize data' },
  ];
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);

  // form for first step
  const {
    control,
    reset,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      firstName: '',
      lastName: '',
      dateOfBirth: undefined as unknown as Date,
      serviceTypeId: '',
      externalClientId: '',
    },
  });

  // bluetooth for second step
  const [watchLoading, setWatchLoading] = useState(false);
  const bluetooth = useBluetooth();
  const { addEventLogData } = useWatchService();
  const [triggerHR, setTriggerHR] = useState(0);
  const [theClient, setTheClient] = useState<any>(null);

  // Step 2: connect to the watch to initiate pairing; leave connected
  const handleWatchPairing = async () => {
    try {
      if (!userProfile) return;
      setWatchLoading(true);
      setStoreConfigState(LoadingState.LOADING);

      if (!getValues().dateOfBirth) return;
      const defaultHr = calculateDefaultHR(getValues().dateOfBirth);
      if (!defaultHr) return;
      const trig:number = Math.min(219, defaultHr.bpmAverage*2);
      console.log("Setting trigger HR to %d", trig);
      setTriggerHR(trig);

      let {ready, device} = await bluetooth.connectBluetooth();
      if (!ready) {
        throw Error("Not ready for some reason"); // FIXME, let exception propagate
      }

      // Successful - now persist client to DB
      await persistClient(device, trig);
    } catch(err) {
      console.log(err);
      bluetooth.disconnect();
    } finally {
      setWatchLoading(false);
    }
  };

  // Third step: sync
  const [storeConfigState, setStoreConfigState] = useState<CallState>(LoadingState.INIT);
  const [eventSyncState, setEventSyncState] = useState<CallState>(LoadingState.INIT);
  const [downloadPct, setDownloadPct] = useState(0);

  const handleStartSync = async () => {
    try {
      if (!getValues().dateOfBirth) return;
      const defaultHr = calculateDefaultHR(getValues().dateOfBirth);
      if (!defaultHr) return;

      if (!userProfile) return;
      // TODO need watch
      setWatchLoading(true);
      setStoreConfigState(LoadingState.LOADING);

      const trig:number = Math.min(219, defaultHr.bpmAverage*2);
      console.log("Setting trigger HR to %d", trig);
      await bluetooth.setHeartRates(defaultHr.bpmAverage, trig);
      setTriggerHR(trig);

      // FUTURE: other config to sync?
      setStoreConfigState(LoadingState.LOADED);
    } catch(err) {
      console.log(err);
      setStoreConfigState({error: "Error syncing config: " + err});
      bluetooth.disconnect();
      setWatchLoading(false);
      return;
    }

    try {
      setEventSyncState(LoadingState.LOADING);

      // Direct callbacks for download progress and save to DB
      // TODO: allow cancel
      await bluetooth.downloadCalooshaData(setDownloadPct, addEventLogData, new CancellationToken());
      setDownloadPct(1); //done

      setEventSyncState(LoadingState.LOADED);
    } catch(err) {
      console.log(err);
      setEventSyncState({error: "Failed to sync eventlog: " + err});
      bluetooth.disconnect();
    } finally {
      setWatchLoading(false);
      // leave bluetooth on for follow-on screen
    }
  };


  // create client before last step
  const { userProfile } = useAppSelector((state) => state.profile);
  const { createClientLoading } = useAppSelector((state) => state.client);
  const { createClient } = useClientService();
  const { calculateDefaultHR } = useWatchSettingsService();
  const [btLoading, setBtLoading] = useState(false);
  const dispatch = useAppDispatch();
  // useEffect(() => {
  //   if (eventSyncState == LoadingState.LOADED) {
  //     handleCloseModal();
  //   }
  // }, [createClientLoading]);

  const handleFinish = async () => {
    handleCloseModal();
    if (theClient != null) {
      navigate(`/dashboard/client/${theClient.id}/watch-settings`);
    }
  }

  const persistClient = async (dev:any, trigHR:number) => {
    // Careful can't use BT or HR state values yet
    try {
      if (!getValues().dateOfBirth) return;
      const defaultHr = calculateDefaultHR(getValues().dateOfBirth);
      if (!defaultHr) return;
      if (!userProfile) return;
      setBtLoading(true);
      const settings: SettingsData = {
        restingHr: defaultHr.bpmAverage,
        triggerHr: trigHR,
        // the following values may need to be defined by the client
        timeoutNavigation: 10,
        timeoutUserPrompt: 30,
        timeoutBluetooth: 60,
        numberOfCycles: 4,
        emojiPrompt1: new Time(9,0),
        emojiPrompt2: new Time(16,0),
        emojiPrompt3: null,
      };
      const client = await createClient(
        {
          ...getValues(),
          therapistId: userProfile.id,
        },
        dev,
        settings,
      );
      setTheClient(client);
      setBtLoading(false);
    } catch (error: any) {
      dispatch(
        showToast({
          summary: 'Error creating client',
          severity: 'error',
          detail: error.message || 'Unable to create client, please try again',
        }),
      );
    }
  };

  return (
    <div className="mx-auto flex w-max flex-col">
      <NewClientFormHeader
        handleCloseModal={handleCloseModal}
        steps={steps}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      />
      {activeIndex === 0 && (
        <NewClientStep1
          handleCloseModal={handleCloseModal}
          setActiveIndex={setActiveIndex}
          control={control}
          reset={reset}
          errors={errors}
          isValid={isValid}
        />
      )}
      {activeIndex === 1 && (
        <NewClientStep2
          setActiveIndex={setActiveIndex}
          handleConnectWatch={handleWatchPairing}
          handleStartSync={handleStartSync}
          watchLoading={watchLoading}
          btDevice={bluetooth.device}
        />
      )}
      {activeIndex === 2 && (
        <NewClientStep3
          setActiveIndex={setActiveIndex}
          handleSubmit={() => handleFinish()}
          triggerHR={triggerHR}
          storeConfigState={storeConfigState}
          eventSyncState={eventSyncState}
          downloadProgress={downloadPct}
        />
      )}
    </div>
  );
};

export default NewClientForm;
