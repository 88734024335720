import React, { useState } from 'react';
import { Chart } from 'primereact/chart';
import { useReducedMotion } from 'framer-motion';
import { ChartData, Color } from 'chart.js';

export type NameValueColor = {
  name : string,
  value : number,
  color : Color
};

const StackedBarChart = ({
  orderedData,
  title
}: {
  orderedData : NameValueColor[],
  title: string
}) => {
  const total = orderedData
    .map(obj => obj.value)
    .reduce((prev, val) => prev + val, 0);

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
      }
    },
    scales: {
      x: {
        display: false,
        stacked: true,
        min: 0,
        max: total, // force max to fill the whole horizontal space
      },
      y: {
        display: false,
        stacked: true,
      },
    },
    indexAxis: 'y'
  };

  const chartData : ChartData<'bar'> = {
    labels: [title],
    datasets: orderedData.map(nvc => { return {
      barThickness: 10,
      label: nvc.name,
      data: [nvc.value],
      backgroundColor: nvc.color
    }})
  };

  return (
    <Chart
      type="bar"
      data={chartData}
      options={options}
      style={{ position: 'relative', height: '50px', width: '100%' }}
    />
  );
}

export default StackedBarChart;
