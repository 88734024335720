import { motion } from 'framer-motion';
import Button from '../../../shared/components/Button';
import { CallState, LoadingState, NewClient } from '../../../shared/types';
import Lottie from 'lottie-react';
import checkmarkAnimation from '../../../shared/helpers/animation/checkmarkAnimation.json';
import errorAnimation from '../../../shared/helpers/animation/errorAnimation.json';
import spinAnimation from '../../../shared/helpers/animation/spinAnimation.json';
import { LinearProgress } from '@mui/material';

const NewClientStep3 = ({
  setActiveIndex,
  handleSubmit,
  triggerHR,
  storeConfigState,
  eventSyncState,
  downloadProgress,
}: {
  setActiveIndex: (index: number) => void;
  handleSubmit: () => void;
  triggerHR: number;
  storeConfigState: CallState;
  eventSyncState: CallState;
  downloadProgress: number; // percent [0...1]
}) => {
  const statusIndication = (state:CallState) => {
    if (state instanceof Object && "error" in state) {
      return (
        <Lottie
          animationData={errorAnimation}
          loop={false}
          className="w-12"
        />)
    } else {
      // LoadingState string enum
      if (state === LoadingState.LOADED) {
        return (
          <Lottie
            animationData={checkmarkAnimation}
            loop={false}
            className="w-12"
          />)
      } else if (state === LoadingState.LOADING) {
        return (
          <Lottie
            animationData={spinAnimation}
            loop={true}
            className="w-12"
          />)
      } else {
        return ""
      }
      
    }
    // TODO: return icon
  }
  return (
    <motion.main className="flex h-[410px] flex-col justify-between">
      <div className="max-w-md text-lg">
        <table>
          <tbody>
            <tr>
              <td>{statusIndication(storeConfigState)}</td>
              <td>Saving trigger heart rate of {triggerHR}</td>
            </tr>
            <tr>
              <td>{statusIndication(eventSyncState)}</td>
              <td>Downloading event data.</td>
            </tr>
          </tbody>
        </table>
        <p className="h-14 justify-stretch">
          {
            (eventSyncState === LoadingState.LOADING || eventSyncState === LoadingState.LOADED)
              ? (<LinearProgress
                    variant='determinate'
                    className='w-full mt-5 mb-5'
                    value={downloadProgress*100}
                  />)
              : (<span></span>)
          }
        </p>
        <p className="mt-4">
          You’ll be able to customize & confirm watch settings on the next
          screen.
        </p>
      </div>
      <div className="flex w-full gap-x-4">
        <Button
          className="p-button-outlined p-button-info w-max"
          onClick={() => setActiveIndex(1)}
          label="Back"
        />
        <Button
          className="p-button-info grow"
          onClick={() => handleSubmit()}
          loading={storeConfigState != LoadingState.LOADED && eventSyncState != LoadingState.LOADED}
          label="Finish"
        />
      </div>
    </motion.main>
  );
};

export default NewClientStep3;
