import StackedBarChart from '../StackedBarChart'
import useChartData from '../../../shared/hooks/useChartData';
import { NameValueColor } from '../StackedBarChart/StackedBarChart';
import { EmojiCheckinDto } from '../../../shared/types';

const CompliancePanel = ({
  emojiData
} : {
  emojiData: EmojiCheckinDto[]
}) => {
  const { getEpisodeResponseCounts } = useChartData();

  const episodeCounts = getEpisodeResponseCounts();
  const totalEpisodes = episodeCounts.yes + episodeCounts.no + episodeCounts.ignore;
  const breathingData:NameValueColor[] = [
    { name:"Accepted", value: Math.round(episodeCounts.yes), color: '#689DFF'},
    { name:"Declined", value: Math.round(episodeCounts.no), color: '#003699'},
    { name:"Ignore", value: Math.round(episodeCounts.ignore), color: '#AAAAAA'},
  ];

  const emojiYes = emojiData.filter(dto => dto.emojiResponse != 0).length;
  const emojiIgn = emojiData.filter(dto => dto.emojiResponse == 0).length;
  const emojiTotal = emojiYes + emojiIgn;
  const emojiSummary: NameValueColor[] = [
    { name:"Accepted", value: emojiYes, color: '#689DFF'},
    { name:"Ignored", value: emojiIgn, color: '#AAAAAA'},
  ];

  return (
    <div>
      <div className="text-xl mb-3 font-bold">
        Compliance
      </div>
      <div className="text-3xl font-bold">
        { totalEpisodes > 0 ? Math.round(100*episodeCounts.yes/totalEpisodes) : "N/A"}%
      </div>
      <p className="text-gray-400">Breathing exercises accepted</p>
      <StackedBarChart
        orderedData={breathingData}
        title="Breathing Prompt Response"
      />
      
      <div className="text-3xl font-bold mt-3">
        { emojiTotal > 0 ? Math.round(100*emojiYes/emojiTotal) : "N/A" }%
      </div>
      <p className="text-gray-400">Emoji check-ins accepted</p>
      <StackedBarChart
        orderedData={emojiSummary}
        title="Emoji Response"
      />

    </div>
  );
};

export default CompliancePanel;
