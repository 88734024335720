
import { addDays, isSameDay } from 'date-fns';
import { EmojiCheckinDto, Time } from '../../../shared/types';
import classNames from 'classnames';
import './EmojiTable.css';
import { motion } from 'framer-motion';

export default function EmojiTable({
  data,
  dateEnd,
  numDays
}: {
  data : EmojiCheckinDto[],
  dateEnd : Date,
  numDays : number
}) {

    // Tailwind CSS can't dynamically make class names; expand full here.
    const tableCols = () => {
        switch (numDays) {
            case 1: return 'grid-cols-1';
            case 7: return 'grid-cols-7';
            case 28: return 'mygrid28'; // custom: stock tailwind doesn't like many columns
            default: return 'grid-cols-7';
        }
    }

    const emojiColorClasses = [
        "bg-gray-100",
        "bg-green-400",
        "bg-blue-400",
        "bg-red-400",
        "bg-yellow-400"
    ];

    const emojiDescription = [
        "Missed",
        "Happy",
        "Sad",
        "Angry",
        "Agitated"
    ];

    const emojiIcons = [
        "/Dashboard_Emoji_Missed.svg",
        "/Dashboard_Emoji_Green.svg",
        "/Dashboard_Emoji_Blue.svg",
        "/Dashboard_Emoji_Red.svg",
        "/Dashboard_Emoji_Yellow.svg",
    ]

    const formatTime = (ent: EmojiCheckinDto) : string => {
        return Time.fromDate(ent.timestamp)?.toString() ?? "";
        // TODO: am/pm?
    }
    const formatEntry = (ent: EmojiCheckinDto) : string => {
        const timeStr = Time.fromDate(ent.timestamp)?.toString() ?? "??";
        return `${timeStr} - ${emojiDescription[ent.emojiResponse]}`;
    };

    const renderDays = () => {
        var dayCells:any[] = [];
        for (var i=0; i<numDays; ++i) {
            const thisDay = addDays(dateEnd, -numDays+i+1);
            const shortDay = `${thisDay.getMonth()+1}/${thisDay.getDate()}`;
            let unique = 0;
            dayCells.push(
                <div key={"day"+shortDay} className='border min-h-20'>
                    <motion.div 
                        className='emojidaycell'
                        initial={{ opacity: 0, scale: 0 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{duration: 0.5 }}
                        >
                        <div className="emojidatetext">
                            {shortDay}
                        </div>
                        { 
                            data
                                .filter(ent => isSameDay(ent.timestamp, thisDay))
                                .map(ent => <div key={unique++} className="emojiresponse min-h-16" title={formatEntry(ent)} aria-label={formatEntry(ent)}>
                                                <div className="emojiicon">
                                                    <img className="w-9" src={emojiIcons[ent.emojiResponse]} />
                                                    <div className="emojiiconcaption">
                                                        {formatTime(ent)}
                                                        <br/>
                                                        {emojiDescription[ent.emojiResponse]}
                                                    </div>
                                                </div>
                                                <div className={classNames("emojicolor", "h-10", emojiColorClasses[ent.emojiResponse])}>
                                                </div>
                                            </div>) 
                        }
                    </motion.div>
                </div>
                );
        }
        return dayCells;
    }

    return (
        <div className={classNames('emojicontainer', 'grid', tableCols(), 'mb-5', 'ml-9', 'mr-2')}>
            { renderDays() }
        </div>
    );
}