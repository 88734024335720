import { LogEntry } from "../types";


export function parseLogEntry(dv:DataView) : LogEntry {
    const ts2k = dv.getInt32(0, true);
    const Epoch2000 = 946684800;
    const unixMs = (Epoch2000 + ts2k)*1000;
    const dt : Date = new Date(unixMs); // watch local time; treat as UTC to avoid TZ munging

    const code = dv.getInt16(4, true);
    const param = dv.getInt16(6, true);
    const ent:LogEntry = {timestamp:dt, code:code, param:param};
    return ent;
}