import { useAuth0 } from '@auth0/auth0-react';
import { differenceInYears, parseISO } from 'date-fns';
import useAuthenticatedRequest from '../hooks/useAuthenticatedRequest';
import { showToast } from '../store/alerts';
import { useAppDispatch, useAppSelector } from '../store/store';
import * as actions from '../store/watch';
import { AddWatchBody, EmojiCheckinDto, LogEntry, SaveWatchDataBody, Watch, WatchData } from '../types';
import { dateAsUTC } from '../helpers/timehelpers';

const useEmojiService = () => {
  const { fetchRequest } = useAuthenticatedRequest();
  const dispatch = useAppDispatch();
  const { client } = useAppSelector((state) => state.client);
  const baseUrl = process.env.REACT_APP_API_URL;
  const { user } = useAuth0();

  const getEmojiData = async (start: Date, end: Date) : Promise<EmojiCheckinDto[]> => {
    try {
      if (!user || !client) throw new Error('User or client not found');
      const emojiDtos = await fetchRequest(`${baseUrl}/emoji-checkin/${client.watch.id}`, {
        method: 'GET',
        params: { 
          start: dateAsUTC(start).toISOString(),
          end: dateAsUTC(end).toISOString() 
        },
      }) as any[];
      return emojiDtos.map(obj => {
        return {
          timestamp: new Date(obj.timestamp),
          emojiResponse: obj.emojiResponse
        };
      });
    } catch (error) {
      console.error('Error getting emoji data', error);
      dispatch(
        showToast({
          severity: 'error',
          summary: 'Error getting emoji data',
          detail: error,
        }),
      );
      throw error;
    }
  };

  return {
    getEmojiData
  };
};

export default useEmojiService;
