import { createSlice } from '@reduxjs/toolkit';
import {
  CallState,
  LoadingState,
  Watch,
  WatchData,
  WatchSettings,
  WatchSettingsDto,
} from '../types';

export interface WatchState {
  watch: Watch | null; // todo - change to correct type
  addWatchLoading: CallState;
  saveWatchDataLoading: CallState;
  watchDataLoading: CallState;
  watchData: WatchData[];
  watchDataCount: number;
  watchEventNotesLoading: CallState;
  chartData: WatchData[];
  chartDataLoading: CallState;
  watchSettings: WatchSettingsDto | null;
  watchSettingsLoading: CallState;
  createWatchSettingsLoading: CallState;
  importCounter: number;
}

export const watchSlice = createSlice({
  name: 'watch',
  initialState: {
    watch: null,
    watchData: [],
    watchDataCount: 0,
    watchEventNotes: '',
    watchEventNotesLoading: LoadingState.INIT,
    chartData: [],
    chartDataLoading: LoadingState.INIT,
    addWatchLoading: LoadingState.INIT,
    saveWatchDataLoading: LoadingState.INIT,
    watchDataLoading: LoadingState.INIT,
    watchSettings: null,
    watchSettingsLoading: LoadingState.INIT,
    createWatchSettingsLoading: LoadingState.INIT,
    importCounter: 0,
  } as WatchState,
  reducers: {
    addWatch: (state) => {
      state.addWatchLoading = LoadingState.LOADING;
    },
    addWatchSuccess: (state, { payload }) => {
      state.addWatchLoading = LoadingState.LOADED;
      state.watch = payload;
    },
    addWatchFailure: (state, { payload }) => {
      state.addWatchLoading = { error: payload };
    },
    saveWatchData: (state) => {
      state.saveWatchDataLoading = LoadingState.LOADING;
    },
    saveWatchDataSuccess: (state, { payload }) => {
      state.saveWatchDataLoading = LoadingState.LOADED;
    },
    saveWatchDataFailure: (state, { payload }) => {
      state.saveWatchDataLoading = { error: payload };
    },
    getWatchData: (state) => {
      state.watchDataLoading = LoadingState.LOADING;
    },
    getWatchDataSuccess: (state, { payload }) => {
      state.watchDataLoading = LoadingState.LOADED;
      state.watchData = payload.data;
      state.watchDataCount = payload.count;
    },
    getWatchDataFailure: (state, { payload }) => {
      state.watchDataLoading = { error: payload };
    },
    updateWatchEventNotes: (state) => {
      state.watchEventNotesLoading = LoadingState.LOADING;
    },
    updateWatchEventNotesSuccess: (state, { payload }) => {
      state.watchEventNotesLoading = LoadingState.LOADED;
      state.watchData = state.watchData.map((data) =>
        data.id === payload.id ? payload : data,
      );
    },
    updateWatchEventNotesFailure: (state, { payload }) => {
      state.watchEventNotesLoading = { error: payload };
    },
    getChartData: (state) => {
      state.chartDataLoading = LoadingState.LOADING;
    },
    getChartDataSuccess: (state, { payload }) => {
      state.chartDataLoading = LoadingState.LOADED;
      state.chartData = payload.data;
    },
    getChartDataFailure: (state, { payload }) => {
      state.chartDataLoading = { error: payload };
    },
    getWatchSettings: (state) => {
      state.watchSettingsLoading = LoadingState.LOADING;
    },
    getWatchSettingsSuccess: (state, { payload }) => {
      state.watchSettingsLoading = LoadingState.LOADED;
      state.watchSettings = payload;
    },
    getWatchSettingsFailure: (state, { payload }) => {
      state.watchSettingsLoading = { error: payload };
    },
    createWatchSettings: (state) => {
      state.createWatchSettingsLoading = LoadingState.LOADING;
    },
    createWatchSettingsSuccess: (state, { payload }) => {
      state.createWatchSettingsLoading = LoadingState.LOADED;
      state.watchSettings = payload;
    },
    createWatchSettingsFailure: (state, { payload }) => {
      state.createWatchSettingsLoading = { error: payload };
    },
    setSingleWatchDataValues: (state, { payload }) => {
      state.watchData = state.watchData.map((data) =>
        data.id === payload.id ? payload : data,
      );
    },
    setImportComplete: (state) => {
      ++state.importCounter;
    },
  },
});

export const {
  addWatch,
  addWatchSuccess,
  addWatchFailure,
  saveWatchData,
  saveWatchDataSuccess,
  saveWatchDataFailure,
  getWatchData,
  getWatchDataSuccess,
  getWatchDataFailure,
  updateWatchEventNotes,
  updateWatchEventNotesSuccess,
  updateWatchEventNotesFailure,
  getChartData,
  getChartDataSuccess,
  getChartDataFailure,
  getWatchSettings,
  getWatchSettingsSuccess,
  getWatchSettingsFailure,
  createWatchSettings,
  createWatchSettingsSuccess,
  createWatchSettingsFailure,
  setSingleWatchDataValues,
  setImportComplete,
} = watchSlice.actions;

export default watchSlice.reducer;
