import { useState } from 'react';
import { Buffer } from 'buffer';
import { slideDownFromTop } from '../../../shared/helpers/animation';
import { motion } from 'framer-motion';
import { ClientWithWatch } from '../../../shared/types';
import Button from '../../../shared/components/Button';
import { Dialog } from 'primereact/dialog';
import useBluetooth from '../../../shared/bluetooth-module/useBluetooth';
import useWatchService from '../../../shared/services/useWatchService';
import BluetoothIcon from '../../../shared/components/BluetoothIcon';
import { useAppSelector } from '../../../shared/store/store';
import * as actions from '../../../shared/store/watch';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showToast } from '../../../shared/store/alerts';
import { LogEntry } from '../../../shared/types';
import { CancellationToken } from '../../../shared/helpers/CancellationToken';
import { LinearProgress } from '@mui/material';

export default function DashboardHeader({
  client,
}: {
  client: ClientWithWatch;
}) {
  const [connecting, setConnecting] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [opCancel, setOpCancel] = useState<CancellationToken|null>(null);
  // Hooks
  const bluetooth = useBluetooth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { addEventLogData, postEpisodeBuffers } = useWatchService();
  

  // Methods
  const downloadData = async () => {
    try {
      // Used to snap to the table screen, but loses the connecting/loading state
      //navigate(`/dashboard/client/${client.id}/table`);

      const token = new CancellationToken();
      setOpCancel(token);
      setConnecting(true);
      await bluetooth.connectNamedBluetooth(client.watch.deviceName);
      setLoadingData(true);

      // First check & sync the time and only passively warn for now
      await bluetooth.syncTime();

      // Direct callbacks for download progress and save to DB
      await bluetooth.downloadCalooshaData(setDownloadProgress, addEventLogData, token);

      // TEMP: scrape the episode buffers for analysis
      setDownloadProgress(0.98);
      console.log("Getting episode buffers");
      const dvs:DataView[] = await bluetooth.getEpisodeBuffers();
      console.log("Got " + dvs.length);
      const arr:string[] = dvs.map(d => {
        return Buffer.from(d.buffer).toString('base64');
      });
      await postEpisodeBuffers(arr);

      // Pause a bit showing complete
      setDownloadProgress(1);
      await new Promise(res => setTimeout(res, 1000));
      // Hoist state of completion so other components can react
      dispatch(actions.setImportComplete());
    } catch (error) {
      console.error(error);
      // no catch; useBluetooth handles all the errors and toast
    } finally {
      setLoadingData(false);
      setConnecting(false);
      setOpCancel(null);
      bluetooth.disconnect();
    }
  };

  const cancelDownload = async () => {
    console.log("Cancelling download...");
    opCancel?.cancel();
  }

  return (
    <header className="mb-6 flex w-full items-center justify-between">
      <div>
        <motion.h1
          className="text-3xl font-bold"
          variants={slideDownFromTop}
          initial="hidden"
          animate="visible"
        >
          {client.firstName} {client.lastName}
        </motion.h1>
        <p className=" font-light text-neutral-500">Wearable Device Data</p>
      </div>
      <div className="flex flex-col">
        {client?.watch?.deviceName && (
          <span className="mb-2 font-light text-gray-800">
            Device: {client?.watch?.deviceName || 'No Device Available'}
          </span>
        )}
        <Button
          onClick={downloadData}
          loading={connecting}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <BluetoothIcon color="#ffffff" className="mr-2" />
          Import new data from watch
        </Button>
      </div>
      <Dialog
          visible={loadingData}
          onHide={() => setLoadingData(false)}
          style={{ width: '400px' }}
          showHeader={false}
          className="rounded-lg"
        >
          <div className="px-6 py-4">
            <div className="center mt-8">
              Downloading data...
            </div>
            <LinearProgress
              variant='determinate'
              className='w-full mt-5 mb-5'
              value={downloadProgress*100}
            />
            <div>
              <Button
                onClick={cancelDownload}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '40%',
                  backgroundColor: 'var(--primary-color)',
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
      </Dialog>
    </header>
  );
}
