import { useEffect, useState, useRef } from 'react';
import { Chart } from 'primereact/chart';
import { ChartData } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { useAppSelector } from '../../../shared/store/store';
import { useReducedMotion } from 'framer-motion';
import { WatchData } from '../../../shared/types';
import { isSameMinute } from 'date-fns/esm';
import { dateAsLocal, dayRange, fourWeekRange, weekRange } from '../../../shared/helpers/timehelpers';

type DatePoint = {d:Date, v:number};
type DateRange = {start:Date, end:Date, tickUnits:'day'|'hour'};
type AllChartData = {
  range: DateRange,
  triggerData: DatePoint[]
  postActionData: DatePoint[]  
};

/**
 * Episode Chart
 *
 * Populating data for the chart based on the averages over the specified interval
 * ex. if the interval is 1 day, the data will be populated with averages for each hour of the day
 *
 */
const EpisodeChart = ({
  date,
  dayViewActive,
  weekViewActive,
  monthViewActive,
}: {
  date: Date;
  dayViewActive?: boolean;
  weekViewActive?: boolean;
  monthViewActive?: boolean;
}) => {
  //Hooks
  const reducedMotion = useReducedMotion();
  const { chartData } = useAppSelector((state) => state.watch);

  // Calculate dates and graph params for this selection
  const { startDate, endDate } = dayViewActive
    ? dayRange(date)
    : weekViewActive
      ? weekRange(date)
      : fourWeekRange(date);
  const tickUnits = dayViewActive ? 'hour': 'day';

  const makeChartData = () => {
    const triggerData: DatePoint[] = chartData
      .map(el => {
        // squash error codes, which should only appear if force triggered
        return {d: dateAsLocal(el.eventDate), v:el.triggerHR > 0 ? el.triggerHR : 0}; 
      });
    const postActionData = chartData
      .filter(el => el.finalHR && el.finalHR > 0 ) // don't show missing or error readings
      .map(el => {
        return {d: dateAsLocal(el.eventDate), v:el.finalHR ?? 0}; 
      });
    return {
      postActionData,
      triggerData,
    };
  };

  const triggerHRPointStyle = {
    label: 'Trigger BPM',
    radius: 8,
    pointHoverRadius: 10,
    backgroundColor: 'rgba(255, 167, 38, 0.8)',
    borderColor: '#FFA726',
  };

  const finalHRPointStyle = {
    label: 'Final BPM',
    radius: 8,
    pointHoverRadius: 10,
    backgroundColor: 'rgba(0, 188, 212, 0.8)',
    borderColor: '#00BCD4',
  };

  //State
  const [data, setData] = useState<ChartData<'scatter', DatePoint[]> | null >(null);

  //Effects
  useEffect(() => {
    const { postActionData, triggerData } = makeChartData();
    // map x,y to DatePoint; needs to be applied per-dataset
    const parsing = {parsing: {
      xAxisKey: 'd',
      yAxisKey: 'v'
    }};
    setData({
      datasets: [
        { ...triggerHRPointStyle, ...parsing, data: triggerData || [] },
        { ...finalHRPointStyle, ...parsing, data: postActionData || [] },
      ],
    });
  }, [
    date,
    dayViewActive,
    weekViewActive,
    monthViewActive,
    JSON.stringify(chartData),
  ]);

  const getLightTheme = () => {
    let basicOptions = {
      animation: !reducedMotion,
      maintainAspectRatio: false,
      showLine: false,
      aspectRatio: 0.8,
      pointDotStrokeWidth: 8,
      plugins: {
        legend: {
          labels: {
            color: '#495057',
          },
          position: 'top',
        },
      },
      scales: {
        x: {
          type: 'time',
          time: {
            unit: 'hour'
          },
          beginAtZero: false,
          min: 0, // set below
          max: 0,
          ticks: {
            color: '#495057',
          },
          grid: {
            color: '#ebedef',
          },
        },
        y: {
          suggestedMin: 60,
          suggestedMax: 150,
          ticks: {
            color: '#495057',
          },
          grid: {
            color: '#ebedef',
          },
        },
      },
    };
    return basicOptions;
  };

  const basicOptions = getLightTheme();
  basicOptions.scales.x.min = startDate.getTime();
  basicOptions.scales.x.max = endDate.getTime();
  basicOptions.scales.x.time.unit = tickUnits;

  // XXX debugging
  const showData = ():ChartData<'scatter', DatePoint[]> | null => {
    console.log(JSON.stringify(data));
    return data;
  };

  return (
    data && (
      <Chart
        type="scatter"
        data={ data ?? undefined }
        options={basicOptions}
        className="my-4 w-full"
      />
    )
  );
};

export default EpisodeChart;
