import useChartData from '../../../shared/hooks/useChartData';
import StackedBarChart from '../StackedBarChart';
import { NameValueColor } from '../StackedBarChart/StackedBarChart';

const BPMChange = () => {
  const { 
    firstBPMChange, 
    secondBPMChange, 
    getEpisodeResultCounts, 
    getEpisodeAvgBPMChange 
  } = useChartData();

  const renderDifference = () => {
    if (!firstBPMChange || !secondBPMChange) return;
    if (firstBPMChange > secondBPMChange) {
      return (
        <span>
          {(firstBPMChange - secondBPMChange).toFixed()}% more than last week
        </span>
      );
    }
    if (firstBPMChange < secondBPMChange) {
      return (
        <span>
          {(secondBPMChange - firstBPMChange).toFixed()}% less than last week
        </span>
      );
    }
    if (firstBPMChange === secondBPMChange) {
      return <span>The same as last week</span>;
    }
  };

  const episodeResults = getEpisodeResultCounts();
  const totalEpisodeResults = episodeResults.good + episodeResults.retry + episodeResults.fail;
  const totalGoodResults = episodeResults.good + episodeResults.retry;
  const episodeResultData:NameValueColor[] = [
    { name:"Lowered BPM on first try", value: episodeResults.good, color: '#3AEC63'},
    { name:"Lowered BPM with retry", value: episodeResults.retry, color: '#0D8C2B'},
    { name:"Did not lower BPM or aborted", value: episodeResults.fail, color: '#F6D13D'},
  ];

  const avgChange = Math.round(100*getEpisodeAvgBPMChange());

  return (
    <div>
      <div className="text-xl mb-3 font-bold">
        Efficacy
      </div>
      <div className='mb-3'>
        <div className="text-3xl font-bold">
          { totalEpisodeResults > 0 ? Math.round(100*totalGoodResults/totalEpisodeResults) : "N/A"}%
        </div>
        <p className="text-gray-400">Exercises that lowered BPM</p>
        <StackedBarChart
          orderedData={episodeResultData}
          title="Breathing Exercise Results"
        />
      </div>

      <span className="text-3xl font-bold">
        {avgChange > 0 ? '+' : ''}{avgChange}%
      </span>
      <p className="text-gray-400">
        { avgChange > 0 
            ? <span>Average <span className='text-red-500'>increase</span> in BPM post-exercise</span>
            : <span>Average decrease in BPM post-exercise</span>
        }
      </p>
      {/*firstBPMChange && secondBPMChange ? (
        <div
          className={`mt-5 flex items-center gap-x-2 font-bold ${
            firstBPMChange < secondBPMChange ? 'text-green-600' : 'text-red-500'
          }`}
        >
          <i
            className={`pi ${
              firstBPMChange < secondBPMChange ? 'pi-arrow-down' : 'pi-arrow-up'
            }`}
          />
          <p>
            {renderDifference()} ({secondBPMChange.toFixed()}%)
          </p>
        </div>
      ) : (
        <div className="mt-5 flex items-center gap-x-2 font-bold">
          No data to compare to last week. XXX
        </div>
      )*/}
    </div>
  );
};

export default BPMChange;
