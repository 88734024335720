import { motion } from 'framer-motion';
import DashboardHeader from '../../components/DashboardHeader';
import { useAppDispatch, useAppSelector } from '../../../shared/store/store';
import { Divider } from 'primereact/divider';
import FormInput from '../../../shared/components/FormInput';
import FormSelect from '../../../shared/components/FormSelect';
import { useForm } from 'react-hook-form';
import Button from '../../../shared/components/Button';
import { Dialog } from 'primereact/dialog';
import './WatchSettings.css';
import useBluetooth from '../../../shared/bluetooth-module/useBluetooth';
import { useEffect, useState } from 'react';
import { CreateWatchSettingsBody, LoadingState, Time } from '../../../shared/types';
import FullPageLoading from '../../../shared/components/FullPageLoading';
import useWatchSettingsService from '../../../shared/services/useWatchSettingsService';
import { showToast } from '../../../shared/store/alerts';
import { useNavigate } from 'react-router-dom';
import FormTimePicker from '../../../shared/components/FormTimePicker';
import { Dropdown } from 'primereact/dropdown';
import { CancellationToken } from '../../../shared/helpers/CancellationToken';

const WatchSettings = () => {
  const { client } = useAppSelector((state) => state.client);
  const { 
    connectNamedBluetooth, 
    setHeartRates,
    setTimeouts,
    forceBreathing,
    forceEmoji,
    disconnect,
    setEmojiTime,
    setNumBreathingCycles 
  } = useBluetooth();
  const dispatch = useAppDispatch();
  const { getWatchSettings, createWatchSettings } = useWatchSettingsService();
  const [settingsSaving, setSettingsSaving] = useState(false);
  const [sendingTriggerBreathing, setSendingTriggerBreathing] = useState(false);
  const [sendingTriggerEmoji, setSendingTriggerEmoji] = useState(false);
  const [currentOpToken, setCurrentOpToken] = useState<CancellationToken|null>(null);
  const { watchSettings, watchSettingsLoading } = useAppSelector(
    (state) => state.watch,
  );
  const [emojiCheckins, setEmojiCheckins] = useState(2);
  const navigate = useNavigate();

  const exitWatchSession = () => {
    disconnect(); //bluetooth
    if (client) {
      navigate(`/dashboard/client/${client.id}/charts`);
    } else {
      navigate(`/dashboard/client-list`);
    }
  }

  const handleSendTrigger = async (
      call: (token:CancellationToken) => Promise<void>,
      stateSetter: (st:boolean) => void
  ) => {
    stateSetter(true);
    const token = new CancellationToken();
    setCurrentOpToken(token);
    try {
      console.log("triggering");
      await connectNamedBluetooth(client?.watch.deviceName);
      await call(token);
    } finally {
      stateSetter(false);
      setCurrentOpToken(null);
    }
  }

  useEffect(() => {
    if (client) {
      getWatchSettings(client.id);
    }
  }, [JSON.stringify(client)]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<CreateWatchSettingsBody, any>({
    mode: 'onTouched',
    defaultValues: {
      restingHr: 65,
      triggerHr: 90,
      timeoutNavigation: 10,
      timeoutUserPrompt: 30,
      timeoutBluetooth: 60,
      numberOfCycles: 2,
      emojiPrompt1: '09:00',
      emojiPrompt2: '16:00',
      emojiPrompt3: '20:00',
    },
  });

  useEffect(() => {
    if (watchSettingsLoading === LoadingState.LOADED && watchSettings) {
      const numCheckins = 
        watchSettings?.emojiPrompt1
          ? watchSettings?.emojiPrompt2
            ? watchSettings?.emojiPrompt3
              ? 3
              : 2
            : 1
          : 0;
      //console.log("This client has " + numCheckins + " emoji checkins");
      setEmojiCheckins(numCheckins);

      //console.log("Populating form with " + JSON.stringify(watchSettings));
      reset(watchSettings); // populates the form
    }
  }, [watchSettingsLoading, JSON.stringify(watchSettings)]);

  const handleSaveSettings = async (data: any) => {
    if (!client) return;
    try {
      setSettingsSaving(true);

      // Save to DB first.
      // ensuring all values are number.  If changed in the UI they become a string.
      await createWatchSettings(
        {
          triggerHr: parseInt(data.triggerHr),
          restingHr: parseInt(data.restingHr),
          timeoutNavigation: parseInt(data.timeoutNavigation),
          timeoutUserPrompt: parseInt(data.timeoutUserPrompt),
          timeoutBluetooth: parseInt(data.timeoutBluetooth),
          numberOfCycles: parseInt(data.numberOfCycles),
          // emoji time fields only hidden; be sure to null out
          emojiPrompt1: emojiCheckins >= 1 ? Time.parse(data.emojiPrompt1) : null,
          emojiPrompt2: emojiCheckins >= 2 ? Time.parse(data.emojiPrompt2) : null,
          emojiPrompt3: emojiCheckins >= 3 ? Time.parse(data.emojiPrompt3) : null,
        },
        client.id,
      );

      // Then sync to watch
      await connectNamedBluetooth(client.watch.deviceName);
      await setHeartRates(data.restingHr, data.triggerHr); // TODO: only if changed
      await setTimeouts(data.timeoutNavigation, data.timeoutUserPrompt); // TODO BT timeout
      await setNumBreathingCycles(data.numberOfCycles);
      await setEmojiTime(1, emojiCheckins >= 1 ? Time.parse(data.emojiPrompt1) : null);
      await setEmojiTime(2, emojiCheckins >= 2 ? Time.parse(data.emojiPrompt2) : null);
      await setEmojiTime(3, emojiCheckins >= 3 ? Time.parse(data.emojiPrompt3) : null);

      dispatch(
        showToast({
          severity: 'success',
          summary: 'Device settings saved!',
        }),
      );
      exitWatchSession();
    } catch (error) {
      dispatch(
        showToast({
          severity: 'error',
          summary: 'Failed to save device settings!',
        }),
      );
    } finally {
      setSettingsSaving(false);
    }
  };

  const options = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  return (
    <div className="w-full p-8">
      {client && (
        <>
          <DashboardHeader client={client} />
          {watchSettingsLoading === LoadingState.INIT ||
          watchSettingsLoading === LoadingState.LOADING ? (
            <>
              <FullPageLoading />
            </>
          ) : (
            <main className="mt-6 flex flex-col rounded-xl border border-gray-200 p-12">
              <section className="flex flex-col">
                <h2 className="text-xl font-bold">Heart Rates</h2>
                <Divider />
                <div className="flex items-center gap-x-24">
                  <div className="flex flex-col gap-y-4">
                    <label
                      htmlFor="restingHr-input"
                      className="text-lg text-gray-700"
                    >
                      Average heart rate
                    </label>
                    <div className="flex items-center gap-x-2">
                      <FormInput
                        name="restingHr"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: 'Required',
                          },
                        }}
                        errors={errors}
                        className="heart-rate-input"
                      />
                      <p className="ml-4 -mt-6 text-lg font-medium text-gray-600">
                        BPM
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col gap-y-4">
                    <label
                      htmlFor="triggerHr-input"
                      className="text-lg text-gray-700"
                    >
                      Trigger heart rate
                    </label>
                    <div className="flex items-center gap-x-2">
                      <FormInput
                        name="triggerHr"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: 'Required',
                          },
                        }}
                        errors={errors}
                        className="heart-rate-input"
                      />
                      <p className="ml-4 -mt-6 text-lg font-medium text-gray-600">
                        BPM
                      </p>
                    </div>
                  </div>
                  {/* <div className="flex flex-col gap-y-4">
                    <label
                      htmlFor="triggerExerciseAfter-input"
                      className="text-lg text-gray-700"
                    >
                      Average Heart Rate
                    </label>
                    <div className="flex items-center gap-x-2">
                      <FormInput
                        name="averageHr"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: 'Required',
                          },
                        }}
                        errors={errors}
                        className="heart-rate-input"
                      />
                      <p className="ml-4 -mt-6 text-lg font-medium text-gray-600">
                        Seconds
                      </p>
                    </div>
                  </div> */}
                  {/* This is in the designs but not defined */}
                  {/* <Button
                    label="Heart rate info"
                    className="p-button-info p-button-outlined h-max"
                    icon="pi pi-question-circle"
                  /> */}
                </div>
              </section>

              <section className="flex flex-col">
                <h2 className="text-xl font-bold">Device Timeouts</h2>
                <Divider />
                <div className="flex flex-wrap items-center gap-x-24">
                  <div className="flex flex-col gap-y-4">
                    <label
                      htmlFor="timeoutNavigation-input"
                      className="text-lg text-gray-700"
                      title="Time before sleeping for ordinary screen navigation"
                    >
                      Navigation Timeout
                    </label>
                    <div className="flex items-center gap-x-2">
                      <FormInput
                        name="timeoutNavigation"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: 'Required',
                          },
                        }}
                        errors={errors}
                        className="heart-rate-input"
                      />
                      <p className="ml-4 -mt-6 text-lg font-medium text-gray-600">
                        seconds
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col gap-y-4">
                    <label
                      htmlFor="timeoutUserPrompt-input"
                      className="text-lg text-gray-700"
                      title="Timeout when the user is prompted for a breathing exercise or emoji check-in, or is setting the time"
                    >
                      User Prompt Timeout
                    </label>
                    <div className="flex items-center gap-x-2">
                      <FormInput
                        name="timeoutUserPrompt"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: 'Required',
                          },
                        }}
                        errors={errors}
                        className="heart-rate-input"
                      />
                      <p className="ml-4 -mt-6 text-lg font-medium text-gray-600">
                        seconds
                      </p>
                    </div>
                  </div>
                  {/*
                  <div className="flex flex-col gap-y-4">
                    <label
                      htmlFor="timeoutBluetooth-input"
                      className="text-lg text-gray-700"
                    >
                      Bluetooth Timeout
                    </label>
                    <div className="flex items-center gap-x-2">
                      <FormInput
                        name="timeoutBluetooth"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: 'Required',
                          },
                        }}
                        errors={errors}
                        className="heart-rate-input"
                      />
                      <p className="ml-4 -mt-6 text-lg font-medium text-gray-600">
                        Seconds
                      </p>
                    </div>
                  </div>
                  */}
                </div>
              </section>

              <section className="flex flex-col">
                <h2 className="text-xl font-bold">Breathing Exercises</h2>
                <Divider />
                <div className="flex items-center gap-x-24">
                  <div className="flex flex-col gap-y-4">
                    <label
                      htmlFor="numberOfCycles-input"
                      className="text-lg text-gray-700"
                    >
                      Number of cycles
                    </label>
                    <div className="flex items-center gap-x-2">
                      <FormSelect
                        name="numberOfCycles"
                        label="Number of cycles"
                        control={control}
                        errors={errors}
                        options={options}
                        rules={{
                          required: {
                            value: true,
                            message: 'Category is required',
                          },
                        }}
                      />
                      <p className="ml-4 -mt-6 text-lg font-medium text-gray-600">
                        Cycles
                      </p>
                    </div>
                  </div>
                </div>
              </section>

              <section className="flex flex-col">
                <h2 className="text-xl font-bold">Emoji Schedule</h2>
                <Divider />
                <div className="flex flex-wrap items-center gap-x-12">
                  <div className="flex flex-col gap-y-4">
                    <label
                      htmlFor="numberOfCheckins-input"
                      className="text-lg text-gray-700"
                    >
                      Number of check-ins per day
                    </label>
                    <div className="flex items-center gap-x-2">
                      <Dropdown
                        id="numberOfCheckins-input"
                        options={['0','1','2','3']} // strings else 0 says 'empty'
                        onChange={(evt) => setEmojiCheckins(parseInt(evt.value))}
                        value={emojiCheckins.toString()}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap items-center mt-8 gap-x-6">
                  <div className={emojiCheckins >= 1 ? "visible" : "invisible"}>
                    <div className="flex flex-col gap-y-4 mb-6">
                      <div className="flex items-center gap-x-2">
                        <FormTimePicker
                          name="emojiPrompt1"
                          label="First check-in"
                          control={control}
                          errors={errors}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={emojiCheckins >= 2 ? "visible" : "invisible"}>
                    <div className="flex flex-col gap-y-4 mb-6">
                      <div className="flex items-center gap-x-2">
                        <FormTimePicker
                          name="emojiPrompt2"
                          label="Second check-in"
                          control={control}
                          errors={errors}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={emojiCheckins >= 3 ? "visible" : "invisible"}>
                    <div className="flex flex-col gap-y-4 mb-6">
                      <div className="flex items-center gap-x-2">
                        <FormTimePicker
                          name="emojiPrompt3"
                          label="Third check-in"
                          control={control}
                          errors={errors}
                        />
                      </div>
                    </div>
                  </div>
                  
                </div>
              </section>

              <section className="flex flex-col">
                <h2 className="text-xl font-bold">Triggers</h2>
                <Divider />
                <div className="flex items-center gap-x-5 mb-10">
                  <Button
                    label="Trigger Breathing Exercise"
                    className="p-button-outlined p-button-info w-max"
                    loading={sendingTriggerBreathing}
                    onClick={ () => handleSendTrigger(forceBreathing, setSendingTriggerBreathing) }
                  />
                  <Button
                    label="Trigger Emoji"
                    className="p-button-outlined p-button-info w-max"
                    loading={sendingTriggerEmoji}
                    onClick={ () => handleSendTrigger(forceEmoji, setSendingTriggerEmoji) }
                  />
                </div>
              </section>

              <div className="flex gap-5">
                <Button
                  label="Cancel"
                  className="p-button-outlined p-button-info w-max"
                  onClick={handleSubmit((data) => exitWatchSession())}
                />
                <Button
                  label="Save all and sync"
                  loading={settingsSaving}
                  onClick={handleSubmit((data) => handleSaveSettings(data))}
                />
              </div>

              <Dialog
                visible={sendingTriggerBreathing || sendingTriggerEmoji}
                onHide={() => { setSendingTriggerBreathing(false); setSendingTriggerEmoji(false); }}
                style={{ width: '400px' }}
                showHeader={false}
                className="rounded-lg"
              >
                <div className="px-6 py-4">
                  <div className="center mt-4 mb-4">
                    Connecting to watch.  Make sure the watch is on the Bluetooth screen.
                  </div>
                  <div>
                    <Button
                      onClick={() => {currentOpToken?.cancel()}}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '40%',
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
            </Dialog>
            </main>
          )}
        </>
      )}
    </div>
  );
};

export default WatchSettings;
