import {
  DEFAULT_CHUNKSIZE,
  CALOOSHA_SERVICE,
  CALOOSHA_FIRMWARE_CHRC,
  CALOOSHA_NUM_ENTRIES_CHRC,
  CALOOSHA_ENTRIES_CHRC,
  CALOOSHA_TRIGGER_HR_CHRC,
  CALOOSHA_FORCE_BREATHING_CHRC,
  CALOOSHA_FORCE_EMOJI_CHRC,
  CALOOSHA_EMOJI_PROMPT_CHRCS,
  CALOOSHA_NUM_BREATHING_CYCLES_CHRC,
  CALOOSHA_EPISODE_BUFFER_CHRCS,
  CALOOSHA_CURRENT_TIME_CHRC,
  CALOOSHA_NAV_TIMEOUT_CHRC,
  CALOOSHA_PROMPT_TIMEOUT_CHRC
} from './constants';
import {
  LogEntry,
  Time,
} from '../types'
import {
  parseLogEntry,
} from './LogEntry'
import { fromTimestamp2k, toTimestamp2k } from '../helpers/timehelpers';

export class BluetoothConnection {
  btServer: any;
  btDevice: any;
  currentName: string|null = null;
  btService : any;
  btReady: boolean = false;

  static _instance: BluetoothConnection;

  constructor() {
    if (BluetoothConnection._instance) {
      return BluetoothConnection._instance;
    }
    BluetoothConnection._instance = this;
  }

  async connect(callback?: (ready: boolean, device: any) => void) : Promise<any> {
    try {
      if (navigator.bluetooth.getDevices) {
        const pairedDevices = await navigator.bluetooth.getDevices();
        console.log('paired devices', pairedDevices);
      }
      console.log('Connecting to bluetooth...' + CALOOSHA_SERVICE);
      const device = await navigator.bluetooth.requestDevice({
        filters: [{ services: [CALOOSHA_SERVICE] }],
        optionalServices: [CALOOSHA_SERVICE],
      });
      console.log('Using device', device);
      device.addEventListener('gattserverdisconnected', this.closeConnection);
      this.btDevice = device;
      this.currentName = device.deviceName;
      //this.btDevice.forget(); // TEMP
      await this.connectToDevice();   

      console.log('Bluetooth ready');
      this.btReady = true;
      if (callback) {
        callback(true, device);
      }
    } catch (error) {
      // strong exception guarantee
      this.btDevice = null;
      this.currentName = null;
      this.btReady = false;
      console.log(error);
      if (callback) {
        callback(false, undefined);
      }
      throw error;
    }
  }

  async connectToNamed(deviceName:string|null|undefined) : Promise<any> {
    try {
      if (!deviceName) {
        throw Error("Need to select a watch to connect to.");
      } 
      console.log('Connecting to ' + deviceName);
      this.currentName = deviceName;
      const device = await navigator.bluetooth.requestDevice({
        filters: [{ services: [CALOOSHA_SERVICE] },
                  { name: deviceName }]
      });
      // TODO: this gets added repeatedly on reuse
      device.addEventListener('gattserverdisconnected', 
                              this.closeConnectionFromEvent,
                              { once: true }); // clean up once fired
      this.btDevice = device;
      console.log("Device selected: " + device);
      await this.connectToDevice();
      this.btReady = true;

      return device;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  private async connectToDevice() : Promise<void> {
    try {
      // connect to device if none is present
      if (!this.btDevice) {
        console.log("WARNING: trying a reconnect");
        await this.connectToNamed(this.currentName); // FIXME: recursive but not really
      }
      if (!this.btDevice) {
        throw new Error('No device found');
      }
      console.log("gatt connect");
      const server = await this.btDevice.gatt.connect();
      console.log('server connected, getting service...');
      this.btServer = server;
      // Hack Windows workaround when downloading big services
      await new Promise(res => setTimeout(res, 1000));
      this.btService = await server.getPrimaryService(CALOOSHA_SERVICE);
      console.log("Found service " + CALOOSHA_SERVICE + ", getting characteristics...");
      // More hack
      await new Promise(res => setTimeout(res, 1000));
      const fwverChrc = await this.btService.getCharacteristic(CALOOSHA_FIRMWARE_CHRC);
      console.log('Checking FW version to trigger security...');
      // Read a characteristic to trigger pairing prompt
      const dvFwVer = await fwverChrc.readValue();
      const major = dvFwVer.getInt8(0);
      const minor = dvFwVer.getInt8(1);
      const micro = dvFwVer.getInt8(2);
      console.log("Remote device version is %d.%d.%d", major, minor, micro);
      const version = (major<<16) | (minor<<8) | micro;
      if (version < 0x000500) {
        console.error("Warning: watch is too old!");
      }
    } catch (error) {
      console.error('error connecting bluetooth:', (error as any)?.code, " ", error);
      if (this.btServer && this.btServer.connected) {
        console.log("Explicit disconnect after a connect failure");
        this.btServer.disconnect()
      }
      this.btServer = null;
      this.btService = null;
      // TODO: auth will look like NetworkError: Authentication failed
      throw error;
    }
  }

  public async getLogEntriesCount() : Promise<number> {
    if (!this.btServer) {
      await this.connectToDevice();
    }
    const numEntriesChrc = await this.btService.getCharacteristic(CALOOSHA_NUM_ENTRIES_CHRC);
    const dvNumEntries = await numEntriesChrc.readValue();
    const count = dvNumEntries.getInt16(0, true); // little endian
    //console.log("Log entries available: %d", count); 
    return count;
  }

  public async getLogEntry() : Promise<LogEntry> {
    if (!this.btServer) {
      await this.connectToDevice();
    } 
    const entriesChrc = await this.btService.getCharacteristic(CALOOSHA_ENTRIES_CHRC);
    const dvEntry = await entriesChrc.readValue();
    return parseLogEntry(dvEntry);
  }

  public async getTime() : Promise<Date> {
    if (!this.btServer) {
      await this.connectToDevice();
    } 
    const chrc = await this.btService.getCharacteristic(CALOOSHA_CURRENT_TIME_CHRC);
    const dvStamp2k = await chrc.readValue();
    const ts2k = dvStamp2k.getInt32(0, true);
    return fromTimestamp2k(ts2k);
  }

  public async setTime(d:Date) : Promise<void> {
    if (!this.btServer) {
      await this.connectToDevice();
    } 
    const chrc = await this.btService.getCharacteristic(CALOOSHA_CURRENT_TIME_CHRC);
    const ts2k = toTimestamp2k(d);
    const buf:ArrayBuffer = this.serializeU32(ts2k);
    await chrc.writeValueWithoutResponse(buf);
  }

  // TODO: getsettings

  public async setTriggerHR(triggerHr:number) : Promise<void> {
    if (!this.btServer) {
      await this.connectToDevice();
    } 
    const triggerHrChrc = await this.btService.getCharacteristic(CALOOSHA_TRIGGER_HR_CHRC);
    const buf:ArrayBuffer = this.serializeU16(triggerHr);
    await triggerHrChrc.writeValueWithoutResponse(buf);
  }

  public async setNavigationTimeout(timeout:number) : Promise<void> {
    if (!this.btServer) {
      await this.connectToDevice();
    } 
    const chrc = await this.btService.getCharacteristic(CALOOSHA_NAV_TIMEOUT_CHRC);
    const buf:ArrayBuffer = this.serializeU16(timeout);
    await chrc.writeValueWithoutResponse(buf);
  }

  public async setUserPromptTimeout(timeout:number) : Promise<void> {
    if (!this.btServer) {
      await this.connectToDevice();
    } 
    const chrc = await this.btService.getCharacteristic(CALOOSHA_PROMPT_TIMEOUT_CHRC);
    const buf:ArrayBuffer = this.serializeU16(timeout);
    await chrc.writeValueWithoutResponse(buf);
  }

  public async setNumBreathingCycles(count:number) : Promise<void> {
    if (!this.btServer) {
      await this.connectToDevice();
    }
    if (count < 1 || count > 10) {
      throw new Error("Breathing cycle count out of range");
    }
    const chrc = await this.btService.getCharacteristic(CALOOSHA_NUM_BREATHING_CYCLES_CHRC);
    const buf:ArrayBuffer = this.serializeU16(count);
    await chrc.writeValueWithoutResponse(buf);
  }

  public async setEmojiTime(which:number, time:Time|null) : Promise<void> {
    if (!this.btServer) {
      await this.connectToDevice();
    } 
    const chrc = await this.btService.getCharacteristic(CALOOSHA_EMOJI_PROMPT_CHRCS[which]);
    const buf = new ArrayBuffer(2);
    const dv:DataView = new DataView(buf);
    if (time) {
      dv.setUint8(0, time.hour);
      dv.setUint8(1, time.minute);
    } else {
      dv.setUint16(0, 0xFFFF, true);
    }
    await chrc.writeValueWithoutResponse(buf);
  }

  private serializeU16(num:number) : ArrayBuffer {
    if (num >= 65536 || num < 0) {
      throw new Error("U16 out of range!");
    }
    const buf = new ArrayBuffer(2);
    const dv:DataView = new DataView(buf);
    dv.setUint16(0, num, true); // little endian
    return buf; // the write needs the buf, not the dv
  }

  private serializeU32(num:number) : ArrayBuffer {
    if (num > 0xFFFFFFFF || num < 0) {
      throw new Error("U32 out of range!");
    }
    const buf = new ArrayBuffer(4);
    const dv:DataView = new DataView(buf);
    dv.setUint32(0, num, true); // little endian
    return buf; // the write needs the buf, not the dv
  }

  public async forceBreathing() : Promise<void> {
    if (!this.btServer) {
      await this.connectToDevice();
    }
    const chrc = await this.btService.getCharacteristic(CALOOSHA_FORCE_BREATHING_CHRC);
    await chrc.writeValueWithoutResponse(new ArrayBuffer(1));
  }

  public async forceEmoji() : Promise<void> {
    if (!this.btServer) {
      await this.connectToDevice();
    }
    const chrc = await this.btService.getCharacteristic(CALOOSHA_FORCE_EMOJI_CHRC);
    await chrc.writeValueWithoutResponse(new ArrayBuffer(1));
  }

  public async readEpisodeBuffers() : Promise<DataView[]> {
    if (!this.btServer) {
      await this.connectToDevice();
    }
    let bufs:DataView[] = [];
    for (const uuid of CALOOSHA_EPISODE_BUFFER_CHRCS) {
      const chrc = await this.btService.getCharacteristic(uuid);
      const dvEntry = await chrc.readValue();
      bufs.push(dvEntry);
    }
    return bufs;
  }

  // Closed from our end
  closeConnection() {
    console.log("Closing connection");
    if (this.btServer) {
      this.btServer.disconnect(); // this may also trigger event.
    }
    this.btServer = undefined;
    this.btService = undefined;
    this.btReady = false;
    this.btDevice = undefined;
    // Don't reset currentName yet
  }

  // Closed from remote end
  closeConnectionFromEvent() {
    console.log("Received gattserverdisconnected");
    this.btServer = undefined;
    this.btService = undefined;
    this.btReady = false;
    this.btDevice = undefined;
  }

}
