import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';
import { Controller } from 'react-hook-form';
import { useEffect, useState } from 'react';

import { TimePicker} from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider, renderTimeViewClock } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
//import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Time } from '../../types/shared-types';

const FormTimePicker = ({
  control,
  errors,
  name,
  label,
  rules,
  defaultValue
}: {
  control: any;
  errors: any;
  name: string;
  label: string;
  rules?: any;
  defaultValue?: Date | null;
}) => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setLoaded(true);
  }, []);


  // Convert whatever input into a Date needed by the Widget
  // This helps avoid time zone issues via use of Date.
  const myValue = (val:Time|string|Date|null) : Date|null => {
    val = val ?? null; // coalesce undefined to null to prevent warning
    //console.log("setting picker to " + JSON.stringify(val));
    if (typeof(val) ===  'string') {
      return Time.parse(val)?.toDate() ?? null;
    } else if (val instanceof Time) {
      return val.toDate();
    }
    // else assume a Date or null obj
    return val;
  }

  // Convert the selected value into the business logic type of Time
  const myOnChange = (value:Date|null, inner:any) => {
    //console.log("Time picker changed to " + value);
    const tm = value ? new Time(value.getHours(), value.getMinutes()) : null;
    inner(tm?.toString()); // useForm is stringly-typed
  }

  return (
    <div className="p-field">
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { onChange, value}, fieldState }) => {
          return (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker 
                label={label}
                className="w-42"
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                  seconds: renderTimeViewClock,
                }}
                //defaultValue={defaultValue}
                value={myValue(value)}
                onChange={(value) => myOnChange(value, onChange)}
              />
            </LocalizationProvider>
          );
        }}
      />
    </div>
  );
};
export default FormTimePicker;
