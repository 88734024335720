import { useAuth0 } from '@auth0/auth0-react';
import { differenceInYears } from 'date-fns';
import useAuthenticatedRequest from '../hooks/useAuthenticatedRequest';
import { useAppDispatch, useAppSelector } from '../store/store';
import { SettingsData } from '../types';
import * as actions from '../store/watch';

const useWatchSettingsService = () => {
  const { fetchRequest } = useAuthenticatedRequest();
  const baseUrl = process.env.REACT_APP_API_URL;
  const dispatch = useAppDispatch();
  const { user } = useAuth0();
  const { heartRateDefaults } = useAppSelector(
    (state) => state.storedConstants,
  );

  const calculateDefaultHR = (dateOfBirth: Date) => {
    let clientAge = differenceInYears(new Date(), dateOfBirth);
    const defaultHr = heartRateDefaults.find((d) => {
      if (clientAge >= 18) {
        // we only have data for 18 and younger
        clientAge = 18;
      }
      if (clientAge === 0) {
        // no data for age of 0, defaulting to 1.
        clientAge = 1;
      }
      return d.age === clientAge;
    });
    return defaultHr;
  };

  const createWatchSettings = async (
    settings: SettingsData,
    clientId: string,
  ) => {
    if (!user) return;
    try {
      dispatch(actions.createWatchSettings());
      // Serialize Time types nicer
      const { emojiPrompt1, emojiPrompt2, emojiPrompt3, ...rest } = settings;
      const watchSettings = await fetchRequest(
        `${baseUrl}/watch-settings/create`,
        {
          method: 'POST',
          body: {
            ...rest,
            emojiPrompt1: emojiPrompt1?.toString() ?? null,
            emojiPrompt2: emojiPrompt2?.toString() ?? null,
            emojiPrompt3: emojiPrompt3?.toString() ?? null,
            clientId,
          },
        },
      );
      dispatch(actions.createWatchSettingsSuccess(watchSettings));
    } catch (error) {
      console.error(error);
      dispatch(actions.createWatchSettingsFailure(error));
    }
  };

  const getWatchSettings = async (clientId: string) => {
    if (!user) return;
    try {
      dispatch(actions.getWatchSettings());
      const watchSettings = await fetchRequest(
        `${baseUrl}/watch-settings/get-by-watch-id/${clientId}`,
        {
          method: 'GET',
        },
      );
      //console.log(JSON.stringify(watchSettings));
      dispatch(actions.getWatchSettingsSuccess(watchSettings));
    } catch (error) {
      dispatch(actions.getWatchSettingsFailure(error));
    }
  };

  return {
    calculateDefaultHR,
    createWatchSettings,
    getWatchSettings,
  };
};

export default useWatchSettingsService;
