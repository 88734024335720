import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CancellationToken } from '../helpers/CancellationToken';
import { showToast } from '../store/alerts';
import { useAppSelector } from '../store/store';
import { BluetoothConnection } from './bluetooth-connection';
import { LogEntry, Time } from '../types';
import { formatDistanceStrict } from 'date-fns';

const useBluetooth = () => {
  const bluetooth = new BluetoothConnection();
  const [bluetoothReady, setBluetoothReady] = useState(false);
  const [device, setDevice] = useState<any>();
  const { client } = useAppSelector((state) => state.client);
  const dispatch = useDispatch();

  // internal helper for error handling
  const handleError = (error: any, summary:string) => {
    var message:string = (error instanceof Error)
        ? error.message
        : (typeof(error) == 'string')
          ? error as string
          : "Unknown error: " + JSON.stringify(error);

    // Override message with more helpful text based on BT code
    // https://source.chromium.org/chromium/chromium/src/+/main:third_party/blink/public/mojom/bluetooth/web_bluetooth.mojom;l=47
    const code = error?.code ?? null;
    console.log("useBluetooth evaluating code " + code);
    switch (code) {
      case 8:
        message = "Connection cancelled."
        break;
      case 9:
        message = "Connection failed with internal error.  Please try again";
        break;
      case 10:
      case 11:
        message = "Pairing failed.  Try to 'Forget' the watch in Windows Bluetooth settings";
        break;
      case 19:
        message = "Can't connect to the watch.  Make sure the watch is on the Bluetooth screen and try again.  If problem persists, try forgetting the watch in your OS Bluetooth settings.";
        break;
    }

    dispatch(
      showToast({
        severity: 'error',
        summary: summary,
        detail: message
      })
    );
    throw error;
  };

  async function runWithRetry<R>( 
      count:number, 
      inner: () => Promise<R>,
      token:CancellationToken
  ) : Promise<R> {
    let lastError:any = null;
    for (let i=0; i<count; ++i) {
      token.check();
      try {
        console.log("Try " + i + " of " + inner);
        return await inner();
      } catch (error) {
        lastError = error;
        const e = error as any;
        console.log("Retry evaluating code " + e.code);
        if ([9, 19].includes(e.code)) {
          console.log("Retrying after error: " + e.message);
        } else {
          throw error;
        }
      }
    }
    throw lastError;
  };

  const connectNewBluetooth = async (): Promise<{
    ready: boolean;
    device: any;
  }> => {
    return new Promise((resolve, reject) => {
      if (!navigator.bluetooth) {
        handleError(new Error("WebBluetooth is not supported in your browser.  Please use Google Chrome."), "Not Supported");
      }
      bluetooth
        .connect((btReady, device) => {
          setDevice(device);
          setBluetoothReady(btReady);
          resolve({ ready: btReady, device });
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const connectNamedBluetooth = async (deviceName:string|undefined) : Promise<any> => {
    if (!deviceName) {
      handleError(new Error("No current client"), "Invalid state");
    }
    if (!navigator.bluetooth) {
      handleError(new Error("WebBluetooth is not supported in your browser.  Please use Google Chrome."), "Not Supported");
    }
    if (bluetooth.btDevice && !checkClientDevice()) {
      console.log("Disconnecting previous BT connection...");
      // different device connected; restart
      bluetooth.closeConnection();
      setDevice(null);
    }
    console.log("connectNamedBluetooth device: " + bluetooth.btDevice);
    if (!bluetooth.btDevice || !bluetooth.btReady) {
      try{
        // Connect can't be retried because it must be part of a user interaction
        const newDev = await bluetooth.connectToNamed(deviceName);
        if (!checkClientDevice()) {
          throw new Error(`Wrong device connected. Please connect the device '${deviceName}' associated with the client`);
        }
        setDevice(newDev);

      } catch (error) {
        handleError(error, "Unable to connect");
      }
    }
    return bluetooth.btDevice;
  }

  useEffect(() => {
    setBluetoothReady(bluetooth.btReady);
  }, [bluetooth.btReady]);

  const checkClientDevice = () => {
    console.log("CHECK: " + client?.watch?.deviceName + "==?" + bluetooth.btDevice?.name
      + ", " + client?.watch?.deviceId + " ==? " + bluetooth.btDevice?.id + ", ready=" + bluetooth.btReady );
    if (
      bluetooth.btReady &&
      bluetooth.btDevice &&
      client &&
      //client.watch?.deviceId !== bluetooth.btDevice?.id  // "uniquely identifiable ID" but it changes over time
      client.watch?.deviceName !== bluetooth.btDevice?.name // not unique but at least sticky
    ) {
      console.log("Found wrong device: " + bluetooth.btDevice?.name);
      bluetooth.closeConnection();
      setBluetoothReady(false);
      return false;
    }
    return true;
  };

  // Positive means watch is ahead; negative means watch is behind.
  const syncTime = async () : Promise<void> => {
    const now:Date = new Date(Date.now());
    let timeIsOff = false;
    try{
      const watchNow:Date = await bluetooth.getTime();
      const diffMs:number = (watchNow.getTime() - now.getTime());
      if (Math.abs(diffMs) >= 120000) { // two minutes of slop allowed
        timeIsOff = true;
        const str:string = formatDistanceStrict(Math.abs(diffMs), 0);
        const dir:string = diffMs > 0 ? "ahead" : "behind";
        dispatch(
          showToast({
            severity: 'warn',
            summary: "Watch time out of sync",
            detail: `Watch is ${dir} by ${str}; event timestamps may be incorrect`
          })
        );
      }
    } catch (error) {
      handleError(error, "Failed to get time from watch");
    }

    // Either way, update the watch time, but only toast if we warned first
    try {
      console.log("Syncing current browser time to watch: " + now);
      await bluetooth.setTime(new Date(Date.now()));

      if (timeIsOff) {
        dispatch(
          showToast({
            severity: 'info',
            summary: "Watch time updated",
            detail: "Watch set to current browser time"
          })
        );
      }
    } catch (error) {
      handleError(error, "Failed to set time on watch");
    }
  }

  // Precondition: bluetooth connected.
  // Postcondition: still connected.
  const setHeartRates = async (base:number, trigger:number) => {
    console.log(`Saving heart rates to watch: avg=${base}, trigger=${trigger}`);
    // TODO: what to do with base?
    try {
      await bluetooth.setTriggerHR(trigger);
    } catch (error) {
      handleError(error, "Failed to save heart rates to watch");
    }
  };

  // Precondition: bluetooth connected.
  // Postcondition: still connected.
  const setTimeouts = async (navigation:number, userPrompt:number) => {
    console.log(`Saving timeouts to watch: nav=${navigation}, prompt=${userPrompt}`);

    if (navigation < 2 || navigation > 300) {
      handleError("Navigation Timeout must be in range 2...300", "Invalid timeout");
    }
    if (userPrompt < 2 || userPrompt > 300) {
      handleError("User Prompt Timeout must be in range 2...300", "Invalid timeout");
    }
    // TODO: bluetooth timeout
    try {
      await bluetooth.setNavigationTimeout(navigation);
      await bluetooth.setUserPromptTimeout(userPrompt);
    } catch (error) {
      handleError(error, "Failed to save timeouts to watch");
    }
  };

  const setNumBreathingCycles = async (count:number) => {
    console.log(`Saving number of breathing cycles to watch: count=${count}`);
    try {
      await bluetooth.setNumBreathingCycles(count);
    } catch (error) {
      handleError(error, "Failed to save number of breathing cycles to watch");
    }
  };

  const setEmojiTime = async (which:number, time:Time|null) : Promise<void> => {
    if (![1,2,3].find(x => x === which)) {
      throw new Error("Invalid emoji time slot");
    }
    console.log(`Saving emoji time ${which}: ${time?.toString()}`);
    try {
      return await bluetooth.setEmojiTime(which, time);
    } catch (error) {
      handleError(error, "Failed to save emoji schedule to watch");
    }
  }

  const getLogEntriesCount = async ():Promise<number> => {
    try {
      return await bluetooth.getLogEntriesCount();
    } catch (error) {
      handleError(error, "Error communicating with watch");
      return -1; // unreachable
    }
  }

  const forceBreathing = async (token:CancellationToken) : Promise<void> => {
    try {
      return await runWithRetry(3, () => bluetooth.forceBreathing(), token);;
    } catch (error) {
      handleError(error, 'Error sending trigger to watch');
    }
  }

  const forceEmoji = async (token:CancellationToken) : Promise<void> => {
    try {
      return await runWithRetry(3, () => bluetooth.forceEmoji(), token);
    } catch (error) {
      handleError(error, 'Error sending trigger to watch');
    }
  }

  const getEpisodeBuffers = async () : Promise<DataView[]> => {
    try {
      return await bluetooth.readEpisodeBuffers();
    } catch (error) {
      console.warn('Error reading episode buffers, skipping...');
      return [];
    }
  }

  const disconnect = () => {
    console.log("Disconnecting...");
    bluetooth.closeConnection(); // not async?
  };

  // Precondition: bluetooth connected.
  // Postcondition: still connected.
  const downloadCalooshaData = async (
    progressCallback : (pct:number) => void,
    logEntryBatchCallback : (ents:LogEntry[]) => Promise<void>,
    token : CancellationToken
    // TODO EventRowCallback for processed entries
  ) : Promise<void> => {
    const totalAvail:number = await getLogEntriesCount();
    token.check();
    console.log("Download data, available: " + totalAvail);
    let totalConsumed:number = 0;
    let batchCount:number = 0;

    const batchSize = 20;
    let ents:LogEntry[] = [];

    try {
      for (let i=0; i<totalAvail; ++i) {
        token.check();
        const ent = await bluetooth.getLogEntry();
        ++totalConsumed;
        progressCallback(totalConsumed / totalAvail);
        if (ent.code <= 0) { continue; } // don't save invalid
        ents.push(ent);
        if (++batchCount > batchSize) {
          await logEntryBatchCallback(ents);
          ents = [];
          batchCount = 0;
        }
      }
    } catch (error) {
      handleError(error, "Error while downloading log data");
    } finally {
      // send remainder or any accumulated on error
      if (batchCount > 0) {
        console.log("Saving last batch of " + batchCount);
        await logEntryBatchCallback(ents);
      }
    }
  };


  return {
    bluetoothReady,
    connectBluetooth: connectNewBluetooth, // fixme: complete rename
    connectNamedBluetooth,
    syncTime,
    setHeartRates,
    setTimeouts,
    setNumBreathingCycles,
    setEmojiTime,
    getLogEntriesCount,
    forceBreathing,
    forceEmoji,
    getEpisodeBuffers,
    disconnect,
    downloadCalooshaData,
    device,
  };
};
export default useBluetooth;
