import { motion } from 'framer-motion';
import { Steps } from 'primereact/steps';
import CloseIcon from '../CloseIcon';

const NewClientFormHeader = ({
  handleCloseModal,
  steps,
  activeIndex,
  setActiveIndex,
}: {
  handleCloseModal: () => void;
  steps: any;
  activeIndex: number;
  setActiveIndex: (index: number) => void;
}) => {
  return (
    <div>
      <header className="flex items-center justify-between">
        <h2 className="my-6 text-3xl font-bold">{steps[activeIndex].header}</h2>
        <CloseIcon handleClick={() => handleCloseModal()} />
      </header>
      <Steps
        model={steps}
        activeIndex={activeIndex}
        //Disallow skip: onSelect={(e: any) => setActiveIndex(e.index)}
        readOnly={false}
        className="mt-4 mb-8 w-full"
      />
    </div>
  );
};

export default NewClientFormHeader;
