
// The type of exception thrown
export class CancellationError extends Error {
    public message = "User cancelled the operation";
}

// A simple cancellation token
export class CancellationToken {
    constructor(private cancelled = false) {}

    public cancel() {
        this.cancelled = true;
    }

    public check() {
        if (this.cancelled) {
            throw new CancellationError();
        }
    }
}