export const NORDIC_DEFAULT_TX_LENGTH = 20; ///< default value for maxPacketLength
export const NORDIC_SERVICE = '6e400001-b5a3-f393-e0a9-e50e24dcca9e';
export const NORDIC_TX = '6e400002-b5a3-f393-e0a9-e50e24dcca9e';
export const NORDIC_RX = '6e400003-b5a3-f393-e0a9-e50e24dcca9e';
export const DEFAULT_CHUNKSIZE = 20;
export const DEVICES = [
  'Puck.js',
  'Pixl.js',
  'MDBT42Q',
  'Espruino',
  'Badge',
  'Thingy',
  'RuuviTag',
  'iTracker',
  'Smartibot',
  'Bangle.js',
  'Micro:bit',
  'Caloosha'
];
export const CSV_FILENAME = 'caloosha_event_log.csv\u0001'; // this is a possible weak point.  if filename ever changes, this will break
export const JS_FILENAME = 'caloosha.app.js'; // this is a possible weak point.  if filename ever changes, this will break

// C2 constants
export const CALOOSHA_SERVICE = '908d1000-19b5-4908-8d9a-2fee3813902e';

export const CALOOSHA_TRIGGER_HR_CHRC = '908d1002-19b5-4908-8d9a-2fee3813902e';
export const CALOOSHA_NAV_TIMEOUT_CHRC = '908d100c-19b5-4908-8d9a-2fee3813902e';
export const CALOOSHA_PROMPT_TIMEOUT_CHRC = '908d100d-19b5-4908-8d9a-2fee3813902e';

export const CALOOSHA_FIRMWARE_CHRC = '908d1fff-19b5-4908-8d9a-2fee3813902e';
export const CALOOSHA_NUM_ENTRIES_CHRC = '908d1101-19b5-4908-8d9a-2fee3813902e';
export const CALOOSHA_ENTRIES_CHRC = '908d1102-19b5-4908-8d9a-2fee3813902e';
export const CALOOSHA_CURRENT_TIME_CHRC = '908d1103-19b5-4908-8d9a-2fee3813902e';
export const CALOOSHA_NUM_BREATHING_CYCLES_CHRC = '908d1003-19b5-4908-8d9a-2fee3813902e';
export const CALOOSHA_EMOJI_PROMPT_CHRCS : string[] = [
  'invalid', // 1-based
  '908d1006-19b5-4908-8d9a-2fee3813902e',
  '908d1007-19b5-4908-8d9a-2fee3813902e',
  '908d1008-19b5-4908-8d9a-2fee3813902e',
];

export const CALOOSHA_FORCE_BREATHING_CHRC = '908d1201-19b5-4908-8d9a-2fee3813902e';
export const CALOOSHA_FORCE_EMOJI_CHRC = '908d1202-19b5-4908-8d9a-2fee3813902e';

export const CALOOSHA_EPISODE_BUFFER_CHRCS : string[] = [
  '908d1301-19b5-4908-8d9a-2fee3813902e',
  '908d1302-19b5-4908-8d9a-2fee3813902e',
  '908d1303-19b5-4908-8d9a-2fee3813902e',
];
